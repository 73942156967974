<ion-content class="bg-n0">
  <div class="px-5 pt-8 pb-10 min-h-100 d-flex flex-column justify-content-between">
    <div class="pt-8">
      @if (canDismiss) {
        <div class="text-end mb-6">
          <button
            type="button"
            [attr.aria-label]="'getCare.inPerson.close' | translate"
            class="btn btn-link p-0"
            (click)="onClose()"
          >
            <span class="icon-close text-n80"></span>
          </button>
        </div>
      }
      <h2 class="heading-32 mb-5 fw-600 g1 pb-1">
        {{ title[lang] }}
      </h2>
      <p class="fs-20 mb-6 lh-md">{{ body[lang] }}</p>
      @if (updates) {
        <div class="gradient-border rounded p-5 mb-6">
          @for (update of updates; track update; let last = $last) {
            <div [ngClass]="{ 'gradient-border-bottom mb-5 pb-5': !last }">
              <h3 class="fs-20 fw-600 mb-2">{{ update.title![lang] }}</h3>
              <p class="fs-18 mb-0 text-n80">{{ update.body![lang] }}</p>
            </div>
          }
        </div>
      }
      @if (footer) {
        <p class="fs-18 mb-0 text-n80 mb-6">{{ footer[lang] }}</p>
      }
    </div>
    <div class="d-grid gap-3">
      @for (button of actions; track $index) {
        <button
          (click)="handleAction(button.action)"
          [ngClass]="{
            'btn-primary': button.isPrimary,
            'btn-outline': !button.isPrimary,
          }"
          class="btn rounded lh-sm area-normal fs-16 py-3 w-100"
        >
          {{ button.label![lang] }}
        </button>
      }
    </div>
  </div>
</ion-content>
