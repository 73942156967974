export const home = {
  greeting: 'Good morning, {{name}}',
  title: 'Thank you for trusting us with your care.',
  watch: 'Watch',
  home: 'Home',
  getCare: 'Get care',
  locations: 'Locations',
  account: 'Account',
  updates: 'We’re grabbing new updates for you now!',
  newVersion: 'A new version of the app is here!',
  someUpdates: 'Here are some exciting updates to make your experience even easier and better:',
  updateNow: 'Update now to explore the latest features and performance upgrades!',
  updateVersion: 'Let’s update to the latest version of the app!',
  ics: {
    virtualAppointment: 'Virtual Appointment',
    urgentCareWalkIn: 'Urgent Care Walk In',
    urgentCareAppointment: 'Urgent Care Appointment',
    nextLevelUrgentCare: 'Next Level Urgent Care',
  },
  actions: {
    updateNow: 'Update Now',
    remind: 'Remind Me Later',
  },
};
