export const migraine = {
  title: {
    anyOfTheseSymptoms: 'Do you have any of these symptoms?',
    whichSymptoms: 'Which symptoms do you have?',
    anyOfTheseApply: 'Do any of these apply to you?',
    skipVisit: 'Can I skip a visit?',
  },
  subtitle: {
    wantMakeSure: 'We want to make sure you don’t have something serious.',
    basedOnResponses:
      'Based on your responses, we agree that you have a migraine. Treatments available using "Skip a Visit" include triptan migraine medications, anti-nausea medication, and prescription strength ibuprofen. Would you like to proceed with skipping your visit?',
  },
  options: {
    worstHeadache: 'Worst headache of my life',
    headInjury: 'Head injury in last 30 days',
    stiffNeck: 'Stiff neck',
    weakness: 'Numbness of weakness of arm or leg',
    facialDroop: 'Facial droop',
    slurredSpeech: 'Slurred speech',
    fever: 'Fever',
    confusion: 'Confusion',
    poundingHeadache: 'Pounding headache',
    lightOrNoise: 'Light or noise makes headache worse',
    nausea: 'Nausea or vomiting with headache',
    similar: 'Symptoms similar to previous migraine',
    vision: 'Vision changes',
    noHistory: 'No history of migraine',
    chance: 'There is a chance I could be pregnant',
    yes: 'Yes',
    no: 'No',
    diabetes: 'Diabetes',
    tobaccoUse: 'Tobacco use',
    over50YearsOld: 'Over 50 years old',
  },
};
