import { Routes } from '@angular/router';

export const InviteRoutes: Routes = [
  {
    path: '',
    pathMatch: 'full',
    redirectTo: 'new',
  },
  {
    path: 'new',
    loadComponent: () => import('./new-user/invite-new-user.page').then((m) => m.InviteNewUserPage),
  },
  {
    path: 'confirm-info',
    loadComponent: () => import('./confirm-info/invite-confirm-info.page').then((m) => m.InviteConfirmInfoPage),
  },
  {
    path: 'request-sent',
    loadComponent: () => import('./request-sent/invite-request-sent.page').then((m) => m.InviteRequestSentPage),
  },
  {
    path: 'upload-photo',
    loadComponent: () => import('./upload-photo/upload-photo.page').then((m) => m.UploadPhotoPage),
  },
  {
    path: 'connected-confirmation',
    loadComponent: () =>
      import('./connected-confirmation/connected-confirmation.page').then((m) => m.ConnectedConfirmationPage),
  },
  {
    path: 'request-access',
    loadComponent: () => import('./request-access/request-access.page').then((m) => m.RequestAccessPage),
  },
  {
    path: 'partial-match',
    loadComponent: () => import('../../registration/partial-match/partial-match.page').then((m) => m.PartialMatchPage),
  },
  {
    path: 'unable-to-match',
    loadComponent: () =>
      import('../../registration/unable-to-match/unable-to-match.page').then((m) => m.UnableToMatchPage),
  },
];
