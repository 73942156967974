export const foodPoisoning = {
  title: {
    anyOfTheseSymptoms: '¿Tienes alguno de estos síntomas?',
    anyOfTheseApply: '¿Alguno de estos se aplica a ti?',
    whichSymptoms: '¿Qué síntomas tienes?',
    howLong: '¿Cuánto tiempo has tenido estos síntomas?',
    pregnantChance: '¿Existe la posibilidad de que estés embarazada?',
  },
  subtitle: {
    wantMakeSure: 'Queremos asegurarnos de que no tengas algo serio.',
  },
  options: {
    yes: 'Sí',
    no: 'No',
    severePain: 'Dolor abdominal severo',
    bloody: 'Heces o vómito con sangre o negras',
    noUrination: 'No orinar durante 12 horas o más',
    unableToDrink: 'Incapacidad para beber líquidos',
    lightheadedness: 'Mareos',
    moreWater: '3 o más deposiciones líquidas al día',
    generalizedPain: 'Dolor abdominal generalizado y cólico',
    nausea: 'Náuseas o vómitos después de comer',
    lessThan: 'Menos de 3 días',
    moreThan: '3 días o más',
    diabetes: 'Diabetes',
    over65: 'Más de 65 años',
    fever: 'Fiebre',
    medicalCondition: 'Condición médica que me pone en mayor riesgo de infección',
    hospitalization: 'Hospitalización en los últimos 30 días',
    antibiotic: 'Uso de antibióticos en los últimos 30 días',
  },
};
