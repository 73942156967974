export const sinusInfection = {
  title: {
    anyOfTheseSymptoms: '¿Tienes alguno de estos síntomas?',
    anyOfTheseApply: '¿Alguno de estos aplica para ti?',
    whichSymptoms: '¿Qué síntomas tienes?',
    howLong: '¿Cuánto tiempo has tenido estos síntomas?',
    wereExposed: '¿Estuviste expuesto a la gripe?',
    covidTest: '¿Tuviste una prueba casera positiva de COVID?',
  },
  subtitle: {
    wantMakeSure: 'Queremos asegurarnos de que no tienes algo grave.',
  },
  options: {
    stiffNeck: 'Cuello rígido o doloroso',
    difficultyBreathing: 'Dificultad para respirar, hablar o tragar',
    severeHeadache: 'Dolor de cabeza severo o que empeora',
    nasalCongestion: 'Congestión nasal',
    facialPain: 'Dolor o presión facial',
    mucusDripping: 'Mucosidad bajando por la garganta',
    symptomsLastingMore: 'Síntomas que duran más de 10 días sin mejorar',
    symptomsGotWorse: 'Los síntomas empeoraron después de mejorar',
    diabetes: 'Diabetes',
    over65: 'Más de 65 años de edad',
    medicalCondition: 'Condición médica que me pone en mayor riesgo de infección',
    tobaccoUser: 'Usuario de tabaco',
    historyOfSinus: 'Historial de cirugía de senos nasales, nasales o faciales',
    moreThan: 'Más de 3 infecciones sinusales en el último año',
    fever: 'Fiebre o escalofríos',
    muscleAches: 'Dolores musculares',
    diarrhea: 'Diarrea',
    soreThroat: 'Dolor de garganta',
    headache: 'Dolor de cabeza',
    cough: 'Tos',
    runnyNose: 'Goteo nasal',
    congestion: 'Congestión',
    lungDisease: 'Enfermedad pulmonar',
    tobacco: 'Uso de tabaco',
    hospitalization: 'Hospitalización por infección pulmonar',
    yes: 'Sí',
    no: 'No',
  },
};
