export const migraine = {
  title: {
    anyOfTheseSymptoms: '¿Tienes alguno de estos síntomas?',
    whichSymptoms: '¿Qué síntomas tienes?',
    anyOfTheseApply: '¿Alguno de estos te aplica?',
    skipVisit: '¿Puedo saltarme una visita?',
  },
  subtitle: {
    wantMakeSure: 'Queremos asegurarnos de que no tengas algo grave.',
    basedOnResponses:
      'Según tus respuestas, estamos de acuerdo en que tienes una migraña. Los tratamientos disponibles usando "Saltar una Visita" incluyen medicamentos para la migraña con triptán, medicamentos contra las náuseas y ibuprofeno de fuerza de prescripción. ¿Te gustaría proceder con saltarte tu visita?',
  },
  options: {
    worstHeadache: 'Peor dolor de cabeza de mi vida',
    headInjury: 'Lesión en la cabeza en los últimos 30 días',
    stiffNeck: 'Rigidez en el cuello',
    weakness: 'Entumecimiento o debilidad de brazo o pierna',
    facialDroop: 'Parálisis facial',
    slurredSpeech: 'Dificultad para hablar',
    fever: 'Fiebre',
    confusion: 'Confusión',
    poundingHeadache: 'Dolor de cabeza pulsante',
    lightOrNoise: 'La luz o el ruido empeoran el dolor de cabeza',
    nausea: 'Náuseas o vómitos con dolor de cabeza',
    similar: 'Síntomas similares a migrañas anteriores',
    vision: 'Cambios en la visión',
    noHistory: 'Sin antecedentes de migraña',
    chance: 'Existe la posibilidad de que esté embarazada',
    yes: 'Sí',
    no: 'No',
    diabetes: 'Diabetes',
    tobaccoUse: 'Uso de tabaco',
    over50YearsOld: 'Mayor de 50 años',
  },
};
