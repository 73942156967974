import { Routes } from '@angular/router';

export const registrationRoutes: Routes = [
  {
    path: '',
    pathMatch: 'full',
    redirectTo: 'info-verify',
  },
  {
    path: 'info-verify',
    loadComponent: () => import('./info-verify/info-verify.page').then((m) => m.InfoVerifyPage),
  },
  {
    path: 'already-verified',
    loadComponent: () => import('./already-verified/already-verified.page').then((m) => m.AlreadyVerifiedPage),
  },
  {
    path: 'address',
    loadComponent: () => import('./address/address.component').then((m) => m.AddressComponent),
  },
  {
    path: 'account-matched',
    loadComponent: () => import('./account-matched/account-matched.page').then((m) => m.AccountMatchedPage),
  },
  {
    path: 'registration-splash',
    loadComponent: () => import('./registration-splash/registration-splash.page').then((m) => m.RegistrationSplashPage),
  },
  {
    path: 'partial-match',
    loadComponent: () => import('./partial-match/partial-match.page').then((m) => m.PartialMatchPage),
  },
  {
    path: 'incorrect-code',
    loadComponent: () => import('./incorrect-code/incorrect-code.page').then((m) => m.IncorrectCodePage),
  },
  {
    path: 'no-match',
    loadComponent: () => import('./no-match/no-match.page').then((m) => m.NoMatchPage),
  },
  {
    path: 'unable-to-match',
    loadComponent: () => import('./unable-to-match/unable-to-match.page').then((m) => m.UnableToMatchPage),
  },
  {
    path: 'contact-confirmation',
    loadComponent: () =>
      import('./contact-confirmation/contact-confirmation.page').then((m) => m.ContactConfirmationPage),
  },
  {
    path: 'contact-summary',
    loadComponent: () => import('./contact-summary/contact-summary.page').then((m) => m.ContactSummaryPage),
  },
  {
    path: 'registration-verification',
    loadComponent: () =>
      import('./registration-verification/registration-verification.page').then((m) => m.RegistrationVerificationPage),
  },
  {
    path: 'dob-verification',
    loadComponent: () => import('./dob-verification/dob-verification.page').then((m) => m.DobVerificationPage),
  },
];
