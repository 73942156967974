import { Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { IonRouterOutlet, IonSpinner, LoadingController, ViewWillEnter } from '@ionic/angular/standalone';
import { AccountService } from './account.service';
import { CapacitorHttp, HttpResponse } from '@capacitor/core';
import { environment } from 'src/environments/environment';
import { AuthService } from 'src/app/services/auth.service';
import { RegistrationService } from 'src/app/services/registration.service';
import { AlertService } from 'src/app/services/alert.service';
import { HttpClient } from '@angular/common/http';
import { firstValueFrom } from 'rxjs';

@Component({
  selector: 'app-get-care-base',
  standalone: true,
  template: `@if (isLoaded) {
    <ion-router-outlet></ion-router-outlet>
  }`,
  imports: [IonRouterOutlet, IonSpinner],
  providers: [RegistrationService],
})
export class AccountBaseComponent implements ViewWillEnter {
  isLoaded: boolean = false;

  constructor(
    private readonly activatedRoute: ActivatedRoute,
    private readonly accountService: AccountService,
    private readonly loadingController: LoadingController,
    private readonly authService: AuthService,
    private readonly alertService: AlertService,
    private readonly httpClient: HttpClient,
  ) {}

  async ionViewWillEnter(): Promise<void> {
    const profileId = this.activatedRoute.snapshot.params['id'];

    const loading = await this.loadingController.create();
    await loading.present();
    try {
      const currentUser = await this.switchAccount(profileId);
      if (currentUser) {
        const userData = await this.fetchUserData(currentUser.id);
        if (userData.status === 200) {
          this.accountService.updateAccount({
            ...currentUser,
            ...userData.data,
            name: `${userData.data.firstName} ${userData.data.lastName}`,
          });
          this.authService.setPrimeAndLanguage(userData.data.metadata.nl.isPrime, userData.data.language);
        }
      }
      this.isLoaded = true;
    } catch (res: any) {
      await this.alertService.showError(res.error.message);
    } finally {
      await this.loadingController.dismiss();
    }
  }

  private async switchAccount(patientId: string): Promise<any> {
    const token = await this.authService.getToken();
    return await firstValueFrom(
      this.httpClient.post(
        `${environment.ollaApiUrl}/api/supertokens/account/switch-account`,
        { patientId },
        {
          headers: {
            'content-type': 'application/json',
            Authorization: `Bearer ${token}`,
            'X-TENANT-ID': environment.orgSlug,
          },
        },
      ),
    );
  }

  private async fetchUserData(profileId: string): Promise<HttpResponse> {
    const accessToken = await this.authService.getToken();
    return (await CapacitorHttp.get({
      headers: {
        'content-type': 'application/json',
        'X-TENANT-ID': environment.orgSlug,
        Authorization: `Bearer ${accessToken}`,
      },
      responseType: 'json',
      url: `${environment.ollaApiUrl}/api/patient/v1/patient/${profileId}/info`,
    })) as HttpResponse;
  }
}
