export const vyi = {
  title: {
    whichSymptoms: '¿Qué síntomas tienes?',
    haveAnyOf: '¿Tienes alguno de los siguientes?',
    anyOfTheseApply: '¿Alguno de estos se aplica a ti?',
    howLong: '¿Cuánto tiempo has tenido estos síntomas?',
    pregnantChance: '¿Existe la posibilidad de que estés embarazada?',
    haveYou:
      '¿Te han recetado un antibiótico y quieres una píldora para prevenir una infección por hongos y actualmente no tienes síntomas?',
  },
  options: {
    yes: 'Sí',
    no: 'No',
    newPartner: 'Nueva pareja sexual',
    painInAbdomen: 'Dolor en el abdomen/lado/espalda',
    fever: 'Fiebre/escalofríos',
    vaginalBleeding: 'Sangrado vaginal',
    sexuallyTransmittedInfection: 'Preocupación por una infección de transmisión sexual',
    newSmell: 'Flujo delgado con nuevo olor o mal olor',
    painfulUrination: 'Micción dolorosa',
    pain: 'Dolor durante el acto sexual',
    vaginalSores: 'Llagas vaginales',
    diabetes: 'Diabetes',
    kidneyDisease: 'Enfermedad renal',
    liverDisease: 'Enfermedad hepática',
    over65: 'Mayor de 65 años',
    medicalCondition: 'Condición médica que me pone en mayor riesgo de infección',
    vaginalDischarge: 'Aumento o cambio en el flujo vaginal',
    vaginalItching: 'Picazón vaginal',
    vaginalIrritation: 'Irritación/ardor vaginal',
    lessThan: 'Menos de 3 días',
    moreThan: '3 días o más',
  },
};
