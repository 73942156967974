export const pinkEye = {
  anySymptoms: {
    title: '¿Tienes alguno de estos síntomas?',
    subtitle: 'Queremos asegurarnos de que no tengas algo serio',
    options: {
      painMovement: 'Dolor con el movimiento ocular o al mirar luces brillantes',
      decrasedAbility: 'Disminución de la capacidad visual',
      recentInjury: 'Lesión reciente en el ojo por un objeto o producto químico',
      severeEyePain: 'Dolor ocular severo',
    },
  },
  whichSymptoms: {
    title: '¿Qué síntomas tienes?',
    options: {
      redWhite: 'Rosa o rojo en la parte blanca de los ojos',
      increasedTearing: 'Aumento en la producción de lágrimas',
      itchy: 'Sensación de picazón, irritación o ardor en los ojos',
    },
  },
  anyApply: {
    title: '¿Alguno de estos se aplica a ti?',
    options: {
      mocous: 'Pus o moco espeso en los ojos.',
      crusting: 'Costras en los párpados o pestañas.',
      watery: 'Secreción clara o acuosa de los ojos',
      bothEyes: 'Síntomas en ambos ojos',
      itchyNose: 'Nariz con picazón',
      sneezing: 'Estornudos',
      scratchy: 'Garganta irritada',
    },
  },
  anyApply2: {
    title: '¿Alguno de estos se aplica a ti?',
    options: {
      contact: 'Lentes de contacto',
      earPain: 'Dolor de oído',
      infectionRisk: 'Condición médica que me pone en mayor riesgo de infección',
    },
  },
  coldWhichSymptoms: {
    title: '¿Qué síntomas tienes?',
    options: {
      fever: 'Fiebre o escalofríos',
      aches: 'Dolores musculares',
      diarrhea: 'Diarrea',
      soreThroat: 'Dolor de garganta',
      headache: 'Dolor de cabeza',
      cough: 'Tos',
      runnyNose: 'Nariz congestionada',
      congestion: 'Congestión',
    },
  },
  coldAnyApply: {
    title: '¿Alguno de estos se aplica a ti?',
    options: {
      diabetes: 'Diabetes',
      lungDisease: 'Enfermedad pulmonar',
      tobacoUse: 'Uso de tabaco',
      hospitalization: 'Hospitalización por infección pulmonar',
      over65: 'Mayor de 65 años',
      infectionRisk: 'Condición médica que me pone en mayor riesgo de infección',
    },
  },
  coldExposure: {
    title: '¿Estuviste expuesto a la gripe?',
    options: {
      yes: 'Sí',
      no: 'No',
    },
  },
  coldCovid: {
    title: '¿Tuviste un resultado positivo en una prueba casera de COVID?',
    options: {
      yes: 'Sí',
      no: 'No',
    },
  },
};
