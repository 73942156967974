export const getCare = {
  title: 'Get care',
  whoWeAreCarringFor: 'Who are we caring for today?',
  findingNearestLocations: 'We’re finding the nearest locations to you!',

  inPerson: {
    title: 'In-person care',
    body: 'Get care at any of our clinics from 9 am to 9 pm, 7 days a week.',
    moreTimes: 'More times',
    pleaseReviewVisit: 'Please review and confirm your visit.',
    upcomingVisitToday: 'Upcoming visit today',
    forName: 'For {{ name }}',
    inPersonAt: 'In-person visit at {{ location }} {{ time }}',
    inPersonInLine: 'In-person visit at {{ location }} {{ userInLine }} in line',
    change: 'Change',
    patientsInLine: 'patients in line',
    patientInLine: 'patient in line',
    close: 'Close',
    call: 'Call',
    directions: 'Directions',
    chat: 'Chat',
    treatInjury: 'Treat an injury',
    consultHealthCoach: 'Consult health coach',
  },
  virtual: {
    title: 'Virtual care',
    body: 'Get care from anywhere.',
    virtualVisit: 'Virtual visit',
    healthCoachVisit: 'Health coach visit',
    chooseSympoms: 'Could you please share with us how you’re feeling, {{ name }}?',
    visitConfirmation: 'We got your request for a virtual visit.',
    willContact: 'We will contact you shortly at {{ phone }} to arrange your virtual visit.',
    pleaseSelectProvider: 'Please select your preferred provider and the best time for your visit.',
    allergies: 'Allergies',
    asthma: 'Asthma',
    cough: 'Cough',
    flu: 'Flu',
    mildNauseaDiarrhea: 'Mild nausea/diarrhea',
    nasalCongestion: 'Nasal congestion',
    painfulUrination: 'Painful urination',
    pinkEye: 'Pink eye',
    rashesSkinIrritation: 'Rashes/skin irritation',
    sinusPain: 'Sinus pain',
    workInjury: 'Work injury',
    annualPhysical: 'Annual physical',
    examForMyChild: 'Well exam for my child',
    chronicHealthIssue: 'Chronic health Issue',
    aboutYourself: 'To get started, could you please tell us a bit more about yourself?',
  },
  skipVisit: {
    title: 'Skip a visit',
    body: 'Get care without having to visit for common concerns and prescriptions.',
    getStarted: 'Let’s figure out the best level of care for {{ name }}, together.',
    howCanWeHelp: 'How can we help you today?',
    assesSymptoms: 'I want to assess symptoms',
    whatUserNeeds: 'Please share with us what {{ name }} needs today.',
    userSick: '{{ name }} is sick and I think they has:',
    userWantMedication: '{{ name }} wants medication for:',
    ruleOutSymptoms: 'To rule out any serious issues, please let us know if {{ name }} has any of these symptoms.',
    anyOfTheseSymptoms: 'Is {{ name }} experiencing any of these symptoms?',
    howLogSymptomsExperienced: 'How long has {{ name }} been dealing with these symptoms?',
    lessThanDay: 'Less than 1 day',
    oneToThreeDays: '1 to 3 days',
    moreThanFiveDays: 'More than 5 days',
    anyOfTheseApply: 'Do any of these apply to {{ name }}?',
    pleaseConfirmAllergies: 'Could you please confirm if {{ name }} has any allergies?',
    pleaseConfirmMedications: 'Please confirm {{ name }} current medications',
    whichPharmacy: 'Which pharmacy should we send the prescriptions to?',
    addAllergy: 'Add allergies.',
    addPhone: 'Enter new phone number',
    allergyList:
      'Describe what changes need to be made to you allergies list. If there is no changes, you can continue without filling the box.',
    medicationList:
      'Describe what changes need to be made to you medications list. If there is no changes, you can continue without filling the box.',
    addMedication: 'Add medications.',
    noCurrentAllergies: 'No current allergies',
    noCurrentMedications: 'No current medications',
    userSkip: 'Great news! {{ name }} can skip the visit.',
    basedOnYourResponses:
      '{{ name }} needs treatment for his {{ treatment }}, which should include an antibiotic and medication to reduce itching, swelling, and redness.',
    treatment: 'Treatment',
    diagnosis: 'Diagnosis',
    nextSteps: 'Next Steps',
    providerWillReview:
      'A provider will review {{ name }} information shortly and send prescriptions to your pharmacy.',
    scheduleVirtualCare:
      'If {{ name }} prefers to speak with a healthcare provider, they can schedule a virtual visit now.',
    selectPharmacy: 'Select pharmacy to continue',
    selectPharmacyForPrescriptions: 'Select the pharmacy for the prescriptions',
    sendToPharmacy: 'Send to this pharmacy',
    yourPreferredPharmacy: 'Your preferred pharmacy',
    findDifferentPharmacy: 'Find a different pharmacy',
    searchClosestToMyLocation: 'Search closest to my location',
    summaryTitle: 'Thank you for trusting us with care today. Here’s your summary.',
    yourPrescriptionWillBeSent: 'Your prescriptions will be sent to this pharmacy',
    visitRecomendTitle: 'With these symptoms, we recommend meeting with a medical provider today.',
    howWouldYouLikeToGetCare: 'How would like to get care for {{ name }} concerns?',
    allSet: 'All set. Thank you for trusting us with {{ name }} care today.',
    summary: 'Here’s the summary:',
    preferredPharmacy: 'Preferred pharmacy',
    meetProvider: 'With these symptoms, recommend {{ name }} to meet with a medical provider today.',
    hotToGetCare: 'How would you like to get care for {{ name }} concerns?',
    treatmentIncludes:
      '{{ name }} treatment will include an appropriate antibiotic and medication to alleviate itching, swelling, and {{ treatment }}.',
    treatIllness: 'Treat an illness',
    medicationRefill: 'Medication refill',
    uti: 'Urinary Tract Infection (UTI)',
    coldFluCovid: 'Cold/flu/COVID',
    pinkEye: 'Pink eye',
    stomachBug: 'Stomach bug',
    sinusInfection: 'Sinus infection',
    foodPoisoning: 'Food poisoning',
    seasonalAllergy: 'Seasonal allergies',
    migraine: 'Migraine',
    ed: 'Erectile Dysfunction (ED)',
    hairLoss: 'Hair Loss',
    hsv: 'Herpes Simplex Virus (HSV)',
    birthControl: 'Birth Control',
    vyi: 'Vaginal Yeast Infection',
    stopSmoking: 'Stop smoking',
    treatments: {
      uti: 'Based on your responses, we agree that you have a urinary tract infection and you can skip your visit. A provider will review your information shortly and send prescriptions to your pharmacy. Your treatment will include an appropriate antibiotic and medication to alleviate painful urination as needed.',
      coldFluCovid:
        'Based on your responses we believe you have the flu and you can skip your visit. Your treatment will include antiviral medications for the flu, cough medication, and prescription strength Ibuprofen for as needed use.',
      pinkEye:
        'Based on your responses, we agree that you have pink eye caused by allergies and you can skip your visit. A provider will review your information shortly and send prescriptions to your pharmacy. Your treatment will include an appropriate antihistamine and allergy eye drops.',
      pinkEye2:
        'Based on your responses, we agree that you have pink eye caused by an infection and you can skip your visit. A provider will review your information shortly and send prescriptions to your pharmacy. Your treatment will include appropriate antibiotic eye drops.',
      stomachBug:
        'Based on your responses, we agree that you have food poisoning, a stomach bug, or viral gastroenteritis and you can skip your visit. A provider will review your information shortly and send prescriptions to your pharmacy.Your treatment will include an appropriate anti-nausea medication and medicine for stomach cramping that can be used as needed.',
      sinusInfection:
        'Based on your responses, we agree that you have a sinus infection and you can skip your visit. A provider will review your information shortly and send prescriptions to your pharmacy. Your treatment will include an appropriate antibiotic and a nasal spray.',
      foodPoisoning:
        'Based on your responses, we agree that you have food poisoning, a stomach bug, or viral gastroenteritis and you can skip your visit. A provider will review your information shortly and send prescriptions to your pharmacy.Your treatment will include an appropriate anti-nausea medication and medicine for stomach cramping that can be used as needed.',
      seasonalAllergy:
        'Based on your responses, we agree that you have seasonal allergies and you can skip your visit. A provider will review your information shortly and send prescriptions to your pharmacy. Your treatment will include an appropriate antihistamine and a nasal spray.',
      migraine:
        'You can skip your visit. A provider will review your information shortly and send prescriptions to your pharmacy. Your treatment will include appropriate prescription strength ibuprofen and anti-nausea medication for as needed use.',
      ed: 'Based on your responses, we agree that medication for erectile dysfunction may be of benefit and you can skip your visit. A provider will review your information shortly and send a prescription for Viagra (sildenafil) 50mg to your pharmacy. You should seek immediate medical attention if you experience chest pain, sudden changes in vision, or an erection that lasts longer than 2 hours.',
      hairLoss:
        'Based on your responses, we agree that medication to help your hair loss is appropriate and you can skip your visit. Your provider will review your information shortly and send you a prescription to your pharmacy.',
      hsv: 'Based on your responses, we agree that daily medication to suppress herpes virus is appropriate and you can skip your visit. A provider will review your information shortly and send a prescription to your pharmacy. Your treatment will include antiviral medication that should be taken daily.',
      hsv2: 'Based on your responses, we agree that medication to treat a herpes outbreak is appropriate and you can skip your visit. A provider will review your information shortly and send a prescription to your pharmacy. Your treatment will include a short course of antiviral medication that should be started within 24 hours of symptom onset.',
      birthControl:
        'Based on your responses, we agree that medication for birth control is appropriate and you can skip your visit. A provider will review your information shortly and send a prescription for a combination hormone birth control pill to your pharmacy. Start your birth control pill pack while you are on your period or the day after your period ends and then take one pill daily at the same time each day after that.',
      vyi: 'Based on your responses, we agree that medication for a vaginal yeast infection is appropriate and you can skip your visit. A provider will review your information shortly and send prescriptions to your pharmacy. Your treatment will include an oral antifungal medication that you will take one time.',
      stopSmoking:
        'Based on your responses, we agree that medication to help you quit smoking is appropriate and you can skip your visit. Provider will review your information shortly and send you a prescription to your pharmacy.',
    },
    weWillReview:
      'We’ll review your request and send the prescription to your pharmacy, then let you know with a text!',
    chatWithTeam: 'Call our Team',
    getMoreInfo:
      'We`d like to get more information from you about your condition. If you agree click below to call our team:',
    concerned: 'We are concerned and would like to speak with you.',
    bestNum: 'Is {{ phone }} the best number to call you?',
    typeHere: 'Type Here ...',
    schedule: 'Based on your responses, we would like you to speak to a medical provider to ensure proper treatment.',
    scheduleSuggestion:
      'This is just a suggestion of where you should go now and must not be relied on over your own judgement.',
    weCallYouAt: 'We`ll call you at {{ phone }}',
    callSoon:
      'We will call you as soon as possible. If you do not receive a call from us in 5 minutes, please call us at {{ servicePhone }}',
  },
  actions: {
    continue: 'Continue',
    complete: 'Complete',
    somethingElse: 'Something else',
    goBack: 'Go Back Home',
    change: 'Change',
    scheduleVisit: 'Schedule a virtual visit',
    getInPerson: 'Get in-person care',
    getVirtual: 'Get virtual care',
    thinkIknow: 'I think I know what {{ name }} needs.',
    needHelp: 'I need help with {{ name }}’s diagnosis.',
    differentPharmacy: 'Find a different pharmacy.',
    preferredPharmacy: 'Send the prescriptions to my preferred pharmacy.',
    noneOfAbove: 'None of the above',
    scheduleAppointment: 'Schedule appointment',
    chatWithTeam: 'Chat with Team',
    callUs: 'Call Us',
    yes: 'Yes',
    no: 'No',
    okay: 'Okay',
  },
  somethingElse: 'Something else',
  someoneElse: 'Someone else',
  location: {
    title: 'Please select a location that works best for you.',
    pageTitle: 'Locations',
    wellnessClinics: 'Employee Wellness Clinics',
    viewWellnessClinics: 'View Employee Wellness Clinics',
    eligibleEmployees: 'For eligible employees and dependents of our partner employers',
    seeLocations: 'See locations',
    scheduleAppointment: 'Schedule an appointment',
    subtitle: 'Using your location',
    pickTime: 'When is the most convenient time for your visit today?',
    today: 'Today',
    tomorrow: 'Tomorrow',
    reviewTime: 'Let’s review & confirm',
    pickedTimeAndLocation: '{{name}} at {{location}} {{time}}',
    pickedFirstInLineLocation: '{{name}} at {{location}} 1st in line',
    edit: 'Edit',
    getInLine: 'Get in line now',
    scheduleForlaterToday: 'Schedule for later today',
    scheduleVirtualVisit: 'Schedule virtual visit',
    scheduleForTomorrow: 'Schedule for tomorrow',
    backInBusiness: {
      today: 'Apologies! We’ll be back in business at 9 AM today.',
      tomorrow: 'Apologies! We’ll be back in business at 9 AM tomorrow morning.',
    },
    requireUrgentCare: 'If you require urgent care at this moment, please don’t hesitate to schedule a virtual visit.',
    seeClinicInfo: 'See clinic information',
    fullyBooked: 'Apologies! We’re fully booked at our {{ location }} clinic today.',
    joinLine: 'If you require urgent care at this moment, please don’t hesitate to join the line.',
    getLine: 'Get In Line',
  },
};
