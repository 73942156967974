import { inject } from '@angular/core';
import { HttpInterceptorFn, HttpErrorResponse, HttpEvent } from '@angular/common/http';
import { catchError, filter, Observable } from 'rxjs';
import { AuthService } from '../services/auth.service';

export const apiInterceptor: HttpInterceptorFn = (req, next) => {
  return next(req).pipe(
    catchError(async (err) => {
      if (err instanceof HttpErrorResponse && err.status === 401) {
        const authService = inject(AuthService);
        await authService.signOut();
        return '401';
      }
      throw err;
    }),
    filter((data) => data !== '401'),
  ) as Observable<HttpEvent<unknown>>;
};
