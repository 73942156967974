export const birthControl = {
  title: {
    age: 'Are you under 18 or over 45 years old?',
    prescriptionReason: 'Why would you like this prescription',
    lastMenstrualPeriod: 'When was your last menstrual period?',
    lastSex: 'Have you had sex since the last menstrual period?',
    bloodPressure:
      'In the last 12 months, have you had a blood pressure reading higher than 140 as top number or higher than 90 as bottom number?',
    haveAnyOfFollowin: 'Do you have any of the following?',
    anyOfTheseApply: 'Do any of these apply to you?',
  },
  options: {
    yes: 'Yes',
    no: 'No',
    idk: 'I don’t know',
    pregnancyPrevention: 'Pregnancy prevention',
    improveMenstrual: 'Improve menstrual periods (more regular or lighter)',
    other: 'Other',
    bloodPressurePeriod: 'Less than 7 days ago',
    lastSexPeriod: 'Between 7 and 30 days ago',
    scheduleAppointmentPeriod: 'More than 30 days ago',
    bloodPressureChecked: 'I have not had my blood pressure checked in the last 12 months',
    heartLiverGallbladder: 'Heart, liver or gallbladder disease',
    historyOfCancer: 'History of cancer, stroke, or blood clots',
    migraines: 'Migraines',
    highBloodPressure: 'High blood pressure',
    highCholesterolLevels: 'High cholesterol levels',
    diabetes: 'Diabetes',
    lupus: 'Lupus',
    currentSmoker: 'Current smoker',
    currentlyPregnant: 'Currently pregnant or breastfeeding',
    deliveredBaby: 'Delivered a baby les than 6 weeks ago',
    historyWeightLoss: 'History of weight loss surgery',
    historySurgery: 'History of surgery requiring hospitalization in last 4 weeks',
    barbiturates: 'Barbiturates',
    antiretrovirals: 'Antiretrovirals',
    carbamazepine: 'Carbamazepine (Tegretol)',
    oxcarbazepine: 'Oxcarbazepine (Trileptal)',
    phenytoin: 'Phenytoin (Dilantin)',
    primidone: 'Primidone (Mysoline)',
    topiramate: 'Topiramate (Topamax)',
    lamotrigine: 'Lamotrigine (Lamictal)',
    rifampin: 'Rifampin or rifabutin (Mycobutin)',
  },
};
