export const pinkEye = {
  anySymptoms: {
    title: 'Do you have any of these symptoms?',
    subtitle: 'We want to make sure you don’t have something serious',
    options: {
      painMovement: 'Pain with eye movement or when looking at bright lights',
      decrasedAbility: 'Decreased ability to see',
      recentInjury: 'Recent injury to eye with object or chemical',
      severeEyePain: 'Severe eye pain',
    },
  },
  whichSymptoms: {
    title: 'Which symptoms do you have?',
    options: {
      redWhite: 'Pink or red in white of eyes',
      increasedTearing: 'Increased tearing',
      itchy: 'Itchy, irritated, or burning feeling in eyes',
    },
  },
  anyApply: {
    title: 'Do any if these apply to you?',
    options: {
      mocous: 'Pus or thick mucous from eyes',
      crusting: 'Crusting of eye lids or lashes',
      watery: 'Clear or watery discharge from eyes',
      bothEyes: 'Symptoms in both eyes',
      itchyNose: 'Itchy nose',
      sneezing: 'Sneezing',
      scratchy: 'Scratchy throat',
    },
  },
  anyApply2: {
    title: 'Do any if these apply to you?',
    options: {
      contact: 'Contact lenses',
      earPain: 'Ear pain',
      infectionRisk: 'Medical condition that puts me at higher risk for infection',
    },
  },
  coldWhichSymptoms: {
    title: 'Which symptoms do you have?',
    options: {
      fever: 'Fever or chills',
      aches: 'Muscle aches',
      diarrhea: 'Diarrhea',
      soreThroat: 'Sore throat',
      headache: 'Headache',
      cough: 'Cough',
      runnyNose: 'Runny nose',
      congestion: 'Congestion',
    },
  },
  coldAnyApply: {
    title: 'Do any of these apply to you?',
    options: {
      diabetes: 'Diabetes',
      lungDisease: 'Lung disease',
      tobacoUse: 'Tobacco use',
      hospitalization: 'Hospitalization for lung infection',
      over65: 'Over 65 years old',
      infectionRisk: 'Medical condition that puts me at higher risk for infection',
    },
  },
  coldExposure: {
    title: 'Were you exposed to the flu?',
    options: {
      yes: 'Yes',
      no: 'No',
    },
  },
  coldCovid: {
    title: 'Did you have positive home COVID test?',
    options: {
      yes: 'Yes',
      no: 'No',
    },
  },
};
