import { Injectable } from '@angular/core';
import { AppUpdate } from '@capawesome/capacitor-app-update';
import { Capacitor, CapacitorHttp } from '@capacitor/core';
import { ModalController } from '@ionic/angular/standalone';
import { environment } from '../../environments/environment';
import { AppUpdatesComponent } from '../shared/app-updates/app-updates.component';

@Injectable({ providedIn: 'root' })
export class UpdateService {
  constructor(private readonly modalController: ModalController) {}
  async init(): Promise<void> {
    try {
      const promises: Promise<any>[] = [];
      promises.push(this.getMaintenanceInfo());
      if (Capacitor.getPlatform() !== 'web') {
        // This check is not applicable for the web
        promises.push(this.getAvailableAppInfo());
      }
      const [maintenance, available] = await Promise.all(promises);
      if (maintenance) await this.presentModal(maintenance);
      if (available) await this.presentModal(available);
    } catch {
      return;
    }
  }

  async getCurrentAppVersion(): Promise<number> {
    const result = await AppUpdate.getAppUpdateInfo();
    if (Capacitor.getPlatform() === 'android') {
      return Number(result.currentVersionCode);
    } else {
      return Number(result.currentVersionName.replaceAll('.', ''));
    }
  }

  async getAvailableAppInfo(): Promise<any> {
    const res = await CapacitorHttp.get({
      url: environment.versionFileLocation,
      responseType: 'blob',
    });
    if (res.status < 200 && res.status >= 400) {
      return undefined;
    }
    const current = await this.getCurrentAppVersion();
    const version = Number(
      (Capacitor.getPlatform() === 'android' ? res.data['android'] : res.data['iOS']).replaceAll('.', ''),
    );
    if (current >= version) {
      return undefined;
    }
    return res.data;
  }

  async getMaintenanceInfo(): Promise<any> {
    const res = await CapacitorHttp.get({
      url: environment.maintenanceFileLocation,
      responseType: 'blob',
    });
    return res.status < 200 && res.status >= 400 ? undefined : res.data;
  }

  async openAppStore(): Promise<void> {
    return AppUpdate.openAppStore();
  }

  private async presentModal(body: any): Promise<any> {
    const modal = await this.modalController.create({
      component: AppUpdatesComponent,
      componentProps: body,
      canDismiss: async (data) => {
        if (body.canDismiss) return true;
        return data?.source === 'action';
      },
      handle: body.canDismiss,
    });
    await modal.present();
    return modal.onDidDismiss();
  }
}
