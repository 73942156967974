export const stomachBug = {
  title: {
    anyOfTheseSymptoms: 'Do you have any of these symptoms?',
    whichSymptoms: 'Which symptoms do you have?',
    anyOfTheseApply: 'Do any of these apply to you?',
    howLong: 'How long have you had these symptoms?',
    pregnantChance: 'Is there a chance you might be pregnant?',
  },
  subtitle: {
    wantMakeSure: 'We want to make sure you don’t have something serious.',
  },
  options: {
    severeAbdominalPain: 'Severe abdominal pain',
    bloodyStools: 'Bloody or black stools or vomit',
    noUrination: 'No urination for 12 hours or more',
    unableToDrinkFluids: 'Unable to drink fluids',
    lightheadedness: 'Lightheadedness',

    wateryStools: '3 or more watery stools per day',
    crampyGeneralized: 'Crampy generalized abdominal pain',
    nausea: 'Nausea or vomiting after eating',
    lessThan: 'Less than 3 days',
    moreThan: '3 or more days',
    yes: 'Yes',
    no: 'No',
    diabetes: 'Diabetes',
    over65: 'Over 65 years old',
    fever: 'Fever',
    antibioticUse: 'Antibiotic use in last 30 days',
    hospitalization: 'Hospitalization in last 30 days',
    medicalCondition: 'Medical condition that puts me at higher risk of infection',
  },
};
