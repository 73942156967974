export const uti = {
  title: {
    anyOfTheseSymptoms: 'Do you have any of these symptoms?',
    whichSymptoms: 'Which symptoms do you have?',
    anyOfTheseApply: 'Do any of these apply to you?',
    howLong: 'How long have you had these symptoms?',
    pregnantChance: 'Is there a chance you might be pregnant?',
  },
  subtitle: {
    wantMakeSure: 'We want to make sure you don’t have something serious.',
  },
  options: {
    fever: 'Fever',
    nausea: 'Nausea/vomiting',
    painInAbdomen: 'Pain in abdomen/side/back',
    vaginalDischarge: 'Vaginal discharge',
    burning: 'Burning',
    frequency: 'Frequency',
    urgency: 'Urgency',
    lessThan: 'Less than 5 days',
    moreThan: '5 or more days',
    yes: 'Yes',
    no: 'No',
    diabetes: 'Diabetes',
    kidneyDisease: 'Kidney disease',
    utiHistory: 'Frequent UTI history',
    hospitalization: 'Hospitalization for UTI',
    over65: 'Over 65 years',
    medicalCondition: 'Medical condition that puts me at higher risk for infection',
    maleReproductive: 'Male reproductive system',
  },
};
