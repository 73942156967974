export const home = {
  greeting: 'Buenos días, {{name}}',
  title: 'Gracias por confiar en nosotros para tu atención.',
  watch: 'Ver',
  home: 'Inicio',
  getCare: 'Asistencia',
  locations: 'Ubicaciones',
  account: 'Cuenta',
  updates: '¡Estamos obteniendo nuevas actualizaciones para ti ahora!',
  newVersion: '¡Una nueva versión de la aplicación está aquí!',
  someUpdates: 'Aquí hay algunas actualizaciones emocionantes para hacer tu experiencia aún más fácil y mejor:',
  updateNow: '¡Actualiza ahora para explorar las últimas funciones y mejoras de rendimiento!',
  updateVersion: '¡Vamos a actualizar a la última versión de la aplicación!',
  ics: {
    virtualAppointment: 'Cita virtual',
    urgentCareWalkIn: 'Atención urgente sin cita previa',
    urgentCareAppointment: 'Cita de atención urgente',
    nextLevelUrgentCare: 'Atención urgente de Next Level',
  },
  actions: {
    updateNow: 'Actualizar ahora',
    remind: 'Recordarme más tarde',
  },
};
