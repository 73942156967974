export const prime = {
  greeting: '¡Buenas noticias! Eres un miembro Prime.',
  scheduleWithPrimeTeam: 'Programa con tu equipo Prime hoy.',
  kickOffPrimeBenefits: 'Comencemos tu viaje Prime con los siguientes beneficios.',
  getMostOutPrime: '¿Qué esperas obtener al máximo de tu membresía Prime?',
  iWantNextLevelToHelpMe: 'Quiero que Next Level Prime me ayude a:',
  introductoryVisit:
    'Tu visita introductoria con el equipo Prime dura unos 15 minutos y es virtual, así que puedes unirte desde casa o el trabajo.',
  whenWouldYouLikeToSchedule: '¿Cuándo te gustaría programar?',
  moreTimes: 'Más horarios',
  continue: 'Continuar',
  interested: 'Estoy interesado',
  doYouHaveAnyOtherCoverage: '¿Tienes alguna otra cobertura de seguro además de Next Level Prime en este momento?',
  careOutsideNextLevelInfo:
    'Nunca facturaremos a tu seguro, pero nuestros navegadores de atención están aquí para ayudarte a encontrar la mejor atención dentro de la red cuando sea necesario.',
  selectTimeThatworks: 'Por favor, selecciona una fecha que te funcione mejor.',
  to: 'a',
  seeMoreDates: 'Ver más fechas',
  noSlots: 'No hay espacios disponibles',
};
