import { Routes } from '@angular/router';
import { primeGuard } from 'src/app/guards/prime.guard';

export const skipVisitRoutes: Routes = [
  {
    path: '',
    canActivate: [primeGuard],
    loadComponent: () => import('./skip-visit.page').then((m) => m.SkipVisitPage),
  },
  {
    path: 'account',
    loadComponent: () => import('../select-account/select-account.page').then((m) => m.SelectAccountPage),
  },
  {
    path: 'choose-symptoms',
    canActivate: [primeGuard],
    loadComponent: () => import('./choose-symptoms/choose-symptoms.page').then((m) => m.ChooseSymptomsPage),
  },
  {
    path: 'chat-with-team',
    canActivate: [primeGuard],
    loadComponent: () => import('./chat-with-team/chat-with-team.page').then((m) => m.ChatWithTeamPage),
  },
  {
    path: 'stepper',
    canActivate: [primeGuard],
    loadComponent: () => import('./visit-stepper/visit-stepper.component').then((m) => m.StepperComponent),
  },
  {
    path: 'select-pharmacy',
    canActivate: [primeGuard],
    loadComponent: () =>
      import('./skip-visit-pharmacy-search/skip-visit-pharmacy-search.component').then(
        (m) => m.SkipVisitPharmacySearchComponent,
      ),
  },
  {
    path: 'preview-pharmacy',
    canActivate: [primeGuard],
    loadComponent: () =>
      import('./skip-visit-pharmacy-preview/skip-visit-pharmacy-preview.component').then(
        (m) => m.SkipVisitPharmacyPreviewComponent,
      ),
  },
  {
    path: 'diagnosis-review',
    canActivate: [primeGuard],
    loadComponent: () => import('./diagnosis-review/diagnosis-review.page').then((m) => m.DiagnosisReviewPage),
  },
];
