export const getCare = {
  title: 'Obtener atención',
  whoWeAreCarringFor: '¿A quién estamos cuidando hoy?',
  findingNearestLocations: '¡Estamos encontrando las ubicaciones más cercanas a ti!',

  inPerson: {
    title: 'Atención en persona',
    body: 'Recibe atención en cualquiera de nuestras clínicas de 9 am a 9 pm, 7 días a la semana.',
    moreTimes: 'Más horarios',
    pleaseReviewVisit: 'Por favor, revisa y confirma tu visita.',
    upcomingVisitToday: 'Próxima visita hoy',
    forName: 'Para {{ name }}',
    inPersonAt: 'Visita en persona en {{ location }} a las {{ time }}',
    inPersonInLine: 'Visita en persona en {{ location }} {{ userInLine }} en la fila',
    change: 'Cambiar',
    patientsInLine: 'pacientes en la fila',
    patientInLine: 'paciente en la fila',
    close: 'Cerrar',
    call: 'Llamar',
    directions: 'Indicaciones',
    chat: 'Chat',
    treatInjury: 'Tratar una lesión',
    consultHealthCoach: 'Consultar con un entrenador de salud',
  },
  virtual: {
    title: 'Atención virtual',
    body: 'Recibe atención desde cualquier lugar.',
    virtualVisit: 'Visita virtual',
    healthCoachVisit: 'Visita con entrenador de salud',
    chooseSympoms: '¿Podrías compartir cómo te sientes, {{ name }}?',
    visitConfirmation: 'Hemos recibido tu solicitud para una visita virtual.',
    willContact: 'Nos pondremos en contacto contigo pronto al {{ phone }} para organizar tu visita virtual.',
    pleaseSelectProvider: 'Por favor, selecciona tu proveedor preferido y el mejor momento para tu visita.',
    allergies: 'Alergias',
    asthma: 'Asma',
    cough: 'Tos',
    flu: 'Gripe',
    mildNauseaDiarrhea: 'Náuseas leves/diarrea',
    nasalCongestion: 'Congestión nasal',
    painfulUrination: 'Micción dolorosa',
    pinkEye: 'Conjuntivitis',
    rashesSkinIrritation: 'Erupciones/irritación de la piel',
    sinusPain: 'Dolor de senos nasales',
    workInjury: 'Lesión en el trabajo',
    annualPhysical: 'Examen físico anual',
    examForMyChild: 'Examen de bienestar para mi hijo',
    chronicHealthIssue: 'Problema de salud crónico',
    aboutYourself: 'Para comenzar, ¿podrías contarnos un poco más sobre ti?',
  },
  skipVisit: {
    title: 'Omitir una visita',
    body: 'Obtén atención sin necesidad de una visita para preocupaciones comunes y recetas.',
    getStarted: 'Vamos a determinar el mejor nivel de atención para {{ name }} juntos.',
    howCanWeHelp: '¿Cómo podemos ayudarte hoy?',
    assesSymptoms: 'Quiero evaluar síntomas',
    whatUserNeeds: 'Por favor, comparte con nosotros lo que {{ name }} necesita hoy.',
    userSick: '{{ name }} está enfermo y creo que tiene:',
    userWantMedication: '{{ name }} quiere medicación para:',
    ruleOutSymptoms:
      'Para descartar cualquier problema serio, por favor dinos si {{ name }} tiene alguno de estos síntomas.',
    anyOfTheseSymptoms: '¿Está {{ name }} experimentando alguno de estos síntomas?',
    howLogSymptomsExperienced: '¿Cuánto tiempo ha estado {{ name }} lidiando con estos síntomas?',
    lessThanDay: 'Menos de 1 día',
    oneToThreeDays: 'De 1 a 3 días',
    moreThanFiveDays: 'Más de 5 días',
    anyOfTheseApply: '¿Alguno de estos aplica a {{ name }}?',
    pleaseConfirmAllergies: '¿Podrías confirmar si {{ name }} tiene alguna alergia?',
    pleaseConfirmMedications: 'Por favor, confirma los medicamentos actuales de {{ name }}',
    whichPharmacy: '¿A qué farmacia debemos enviar las recetas?',
    addAllergy: 'Agregar alergias.',
    addPhone: 'Ingresar nuevo número de teléfono',
    allergyList:
      'Describe qué cambios deben hacerse en tu lista de alergias. Si no hay cambios, puedes continuar sin llenar el cuadro.',
    medicationList:
      'Describe qué cambios deben hacerse en tu lista de medicamentos. Si no hay cambios, puedes continuar sin llenar el cuadro.',
    addMedication: 'Agregar medicamentos.',
    noCurrentAllergies: 'No hay alergias actuales',
    noCurrentMedications: 'No hay medicamentos actuales',
    userSkip: '¡Buenas noticias! {{ name }} puede omitir la visita.',
    basedOnYourResponses:
      '{{ name }} necesita tratamiento para su {{ treatment }}, lo que debería incluir un antibiótico y medicamentos para reducir la picazón, hinchazón y enrojecimiento.',
    treatment: 'Tratamiento',
    diagnosis: 'Diagnóstico',
    nextSteps: 'Próximos pasos',
    providerWillReview:
      'Un proveedor revisará la información de {{ name }} en breve y enviará las recetas a tu farmacia.',
    scheduleVirtualCare:
      'Si {{ name }} prefiere hablar con un proveedor de salud, puede programar una visita virtual ahora.',
    selectPharmacy: 'Selecciona una farmacia para continuar',
    selectPharmacyForPrescriptions: 'Selecciona la farmacia para las recetas',
    sendToPharmacy: 'Enviar a esta farmacia',
    yourPreferredPharmacy: 'Tu farmacia preferida',
    findDifferentPharmacy: 'Buscar una farmacia diferente',
    searchClosestToMyLocation: 'Buscar la más cercana a mi ubicación',
    summaryTitle: 'Gracias por confiar en nosotros para la atención de hoy. Aquí está tu resumen.',
    yourPrescriptionWillBeSent: 'Tus recetas serán enviadas a esta farmacia',
    visitRecomendTitle: 'Con estos síntomas, recomendamos que te reúnas con un proveedor médico hoy.',
    howWouldYouLikeToGetCare: '¿Cómo te gustaría obtener atención para las preocupaciones de {{ name }}?',
    allSet: 'Todo listo. Gracias por confiar en nosotros para el cuidado de {{ name }} hoy.',
    summary: 'Aquí está el resumen:',
    preferredPharmacy: 'Farmacia preferida',
    meetProvider: 'Con estos síntomas, recomendamos que {{ name }} se reúna con un proveedor médico hoy.',
    hotToGetCare: '¿Cómo te gustaría obtener atención para las preocupaciones de {{ name }}?',
    treatmentIncludes:
      'El tratamiento de {{ name }} incluirá un antibiótico adecuado y medicamentos para aliviar la picazón, hinchazón y {{ treatment }}.',
    treatIllness: 'Tratar una enfermedad',
    medicationRefill: 'Recarga de medicamentos',
    uti: 'Infección del tracto urinario (ITU)',
    coldFluCovid: 'Resfriado/gripe/COVID',
    pinkEye: 'Conjuntivitis',
    stomachBug: 'Virus estomacal',
    sinusInfection: 'Infección de los senos nasales',
    foodPoisoning: 'Intoxicación alimentaria',
    seasonalAllergy: 'Alergias estacionales',
    migraine: 'Migraña',
    ed: 'Disfunción eréctil (DE)',
    hairLoss: 'Pérdida de cabello',
    hsv: 'Virus del herpes simple (HSV)',
    birthControl: 'Control de la natalidad',
    vyi: 'Infección por levaduras vaginales',
    stopSmoking: 'Dejar de fumar',
    treatments: {
      uti: 'Según tus respuestas, coincidimos en que tienes una infección del tracto urinario y puedes omitir la visita. Un proveedor revisará tu información en breve y enviará las recetas a tu farmacia. Tu tratamiento incluirá un antibiótico adecuado y medicamentos para aliviar la micción dolorosa según sea necesario.',
      coldFluCovid:
        'Según tus respuestas, creemos que tienes la gripe y puedes omitir la visita. Tu tratamiento incluirá medicamentos antivirales para la gripe, medicamentos para la tos y ibuprofeno de prescripción para uso según sea necesario.',
      pinkEye:
        'Según tus respuestas, coincidimos en que tienes conjuntivitis causada por alergias y puedes omitir la visita. Un proveedor revisará tu información en breve y enviará las recetas a tu farmacia. Tu tratamiento incluirá un antihistamínico adecuado y gotas para los ojos para la alergia.',
      pinkEye2:
        'Según tus respuestas, coincidimos en que tienes conjuntivitis causada por una infección y puedes omitir la visita. Un proveedor revisará tu información en breve y enviará las recetas a tu farmacia. Tu tratamiento incluirá gotas para los ojos con antibióticos adecuados.',
      stomachBug:
        'Según tus respuestas, coincidimos en que tienes una intoxicación alimentaria, un virus estomacal o gastroenteritis viral y puedes omitir la visita. Un proveedor revisará tu información en breve y enviará las recetas a tu farmacia. Tu tratamiento incluirá un medicamento adecuado contra las náuseas y medicina para los calambres estomacales que se puede usar según sea necesario.',
      sinusInfection:
        'Según tus respuestas, coincidimos en que tienes una infección de los senos nasales y puedes omitir la visita. Un proveedor revisará tu información en breve y enviará las recetas a tu farmacia. Tu tratamiento incluirá un antibiótico adecuado y un aerosol nasal.',
      foodPoisoning:
        'Según tus respuestas, coincidimos en que tienes una intoxicación alimentaria, un virus estomacal o gastroenteritis viral y puedes omitir la visita. Un proveedor revisará tu información en breve y enviará las recetas a tu farmacia. Tu tratamiento incluirá un medicamento adecuado contra las náuseas y medicina para los calambres estomacales que se puede usar según sea necesario.',
      seasonalAllergy:
        'Según tus respuestas, coincidimos en que tienes alergias estacionales y puedes omitir la visita. Un proveedor revisará tu información en breve y enviará las recetas a tu farmacia. Tu tratamiento incluirá un antihistamínico adecuado y un aerosol nasal.',
      migraine:
        'Puedes omitir tu visita. Un proveedor revisará tu información en breve y enviará las recetas a tu farmacia. Tu tratamiento incluirá ibuprofeno de prescripción adecuado y medicamentos contra las náuseas para uso según sea necesario.',
      ed: 'Según tus respuestas, coincidimos en que la medicación para la disfunción eréctil puede ser beneficiosa y puedes omitir la visita. Un proveedor revisará tu información en breve y enviará una receta de Viagra (sildenafil) 50mg a tu farmacia. Debes buscar atención médica inmediata si experimentas dolor en el pecho, cambios repentinos en la visión o una erección que dure más de 2 horas.',
      hairLoss:
        'Según tus respuestas, coincidimos en que la medicación para ayudar con la pérdida de cabello es adecuada y puedes omitir la visita. Tu proveedor revisará tu información en breve y te enviará una receta a tu farmacia.',
      hsv: 'Según tus respuestas, coincidimos en que la medicación diaria para suprimir el virus del herpes es adecuada y puedes omitir la visita. Un proveedor revisará tu información en breve y enviará una receta a tu farmacia. Tu tratamiento incluirá medicamentos antivirales que deben tomarse diariamente.',
      hsv2: 'Según tus respuestas, coincidimos en que la medicación para tratar un brote de herpes es adecuada y puedes omitir la visita. Un proveedor revisará tu información en breve y enviará una receta a tu farmacia. Tu tratamiento incluirá un curso corto de medicamentos antivirales que deben comenzar dentro de las 24 horas posteriores al inicio de los síntomas.',
      birthControl:
        'Según tus respuestas, coincidimos en que la medicación para el control de la natalidad es adecuada y puedes omitir la visita. Un proveedor revisará tu información en breve y enviará una receta para una píldora anticonceptiva hormonal combinada a tu farmacia. Comienza tu paquete de píldoras anticonceptivas mientras estás en tu período o el día después de que termine tu período y luego toma una píldora diariamente a la misma hora cada día.',
      vyi: 'Según tus respuestas, coincidimos en que la medicación para una infección vaginal por levaduras es adecuada y puedes omitir la visita. Un proveedor revisará tu información en breve y enviará las recetas a tu farmacia. Tu tratamiento incluirá un medicamento antifúngico oral que tomarás una vez.',
      stopSmoking:
        'Según tus respuestas, coincidimos en que la medicación para ayudarte a dejar de fumar es adecuada y puedes omitir la visita. Un proveedor revisará tu información en breve y te enviará una receta a tu farmacia.',
    },
    weWillReview:
      'Revisaremos tu solicitud y enviaremos la receta a tu farmacia, luego te lo informaremos con un mensaje de texto.',
    chatWithTeam: 'Chatear con el equipo',
    getMoreInfo:
      'Nos gustaría obtener más información sobre tu condición. Si estás de acuerdo, haz clic abajo para llamar a nuestro equipo:',
    concerned: 'Estamos preocupados y nos gustaría hablar contigo.',
    bestNum: '¿Es {{ phone }} el mejor número para llamarte?',
    typeHere: 'Escribe aquí...',
    schedule:
      'Según tus respuestas, nos gustaría que hables con un proveedor médico para asegurar un tratamiento adecuado.',
    scheduleSuggestion:
      'Esta es solo una sugerencia de adónde debes ir ahora y no debe ser considerada más allá de tu propio juicio.',
    weCallYouAt: 'Te llamaremos al {{ phone }}',
    callSoon:
      'Te llamaremos lo antes posible. Si no recibes una llamada nuestra en 5 minutos, por favor llámanos al {{ servicePhone }}',
  },
  actions: {
    continue: 'Continuar',
    complete: 'Completar',
    somethingElse: 'Otra cosa',
    goBack: 'Volver a casa',
    change: 'Cambiar',
    scheduleVisit: 'Programar una visita virtual',
    getInPerson: 'Obtener atención en persona',
    getVirtual: 'Obtener atención virtual',
    thinkIknow: 'Creo que sé lo que {{ name }} necesita.',
    needHelp: 'Necesito ayuda con el diagnóstico de {{ name }}.',
    differentPharmacy: 'Buscar una farmacia diferente.',
    preferredPharmacy: 'Enviar las recetas a mi farmacia preferida.',
    noneOfAbove: 'Ninguna de las anteriores',
    scheduleAppointment: 'Programar cita',
    chatWithTeam: 'Chatear con el equipo',
    yes: 'Sí',
    no: 'No',
    okay: 'Ok',
  },
  somethingElse: 'Otra cosa',
  someoneElse: 'Alguien más',
  location: {
    title: 'Por favor, selecciona una ubicación que funcione mejor para ti.',
    pageTitle: 'Ubicaciones',
    wellnessClinics: 'Clínicas de bienestar para empleados',
    viewWellnessClinics: 'Ver Clínicas de Bienestar para Empleados',
    eligibleEmployees: 'Para empleados elegibles y dependientes de nuestros empleadores asociados',
    seeLocations: 'Ver ubicaciones',
    scheduleAppointment: 'Programar una cita',
    subtitle: 'Usando tu ubicación',
    pickTime: '¿Cuál es el momento más conveniente para tu visita hoy?',
    today: 'Hoy',
    tomorrow: 'Mañana',
    reviewTime: 'Revisemos y confirmemos',
    pickedTimeAndLocation: '{{name}} en {{location}} a las {{time}}',
    pickedFirstInLineLocation: '{{name}} en {{location}} 1ro en la fila',
    edit: 'Editar',
    getInLine: 'Ponte en la fila ahora',
    scheduleForlaterToday: 'Programar para más tarde hoy',
    scheduleVirtualVisit: 'Programar visita virtual',
    scheduleForTomorrow: 'Programar para mañana',
    backInBusiness: {
      today: '¡Lo sentimos! Volveremos a estar en servicio hoy a las 8 AM.',
      tomorrow: '¡Lo sentimos! Volveremos a estar en servicio mañana a las 8 AM.',
    },
    requireUrgentCare: 'Si necesitas atención urgente en este momento, no dudes en programar una visita virtual.',
    seeClinicInfo: 'Ver información de la clínica',
    fullyBooked: '¡Lo sentimos! Estamos totalmente reservados en nuestra clínica de {{ location }} hoy.',
    joinLine: 'Si necesitas atención urgente en este momento, no dudes en unirte a la fila.',
    getLine: 'Ponte en la fila',
  },
};
