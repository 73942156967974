export const birthControl = {
  title: {
    age: '¿Tienes menos de 18 o más de 45 años?',
    prescriptionReason: '¿Por qué te gustaría esta receta?',
    lastMenstrualPeriod: '¿Cuándo fue tu última menstruación?',
    lastSex: '¿Has tenido relaciones sexuales desde tu última menstruación?',
    bloodPressure:
      'En los últimos 12 meses, ¿has tenido una lectura de presión arterial superior a 140 como número superior o superior a 90 como número inferior?',
    haveAnyOfFollowin: '¿Tienes alguno de los siguientes?',
    anyOfTheseApply: '¿Alguno de estos se aplica a ti?',
  },
  options: {
    yes: 'Sí',
    no: 'No',
    idk: 'No lo sé',
    pregnancyPrevention: 'Prevención del embarazo',
    improveMenstrual: 'Mejorar los períodos menstruales (más regulares o más ligeros)',
    other: 'Otro',
    bloodPressurePeriod: 'Hace menos de 7 días',
    lastSexPeriod: 'Entre 7 y 30 días atrás',
    scheduleAppointmentPeriod: 'Hace más de 30 días',
    bloodPressureChecked: 'No me han revisado la presión arterial en los últimos 12 meses',
    heartLiverGallbladder: 'Enfermedad del corazón, hígado o vesícula biliar',
    historyOfCancer: 'Historial de cáncer, accidente cerebrovascular o coágulos de sangre',
    migraines: 'Migrañas',
    highBloodPressure: 'Presión arterial alta',
    highCholesterolLevels: 'Niveles altos de colesterol',
    diabetes: 'Diabetes',
    lupus: 'Lupus',
    currentSmoker: 'Fumador actual',
    currentlyPregnant: 'Actualmente embarazada o amamantando',
    deliveredBaby: 'Dio a luz hace menos de 6 semanas',
    historyWeightLoss: 'Historial de cirugía de pérdida de peso',
    historySurgery: 'Historial de cirugía que requirió hospitalización en las últimas 4 semanas',
    barbiturates: 'Barbitúricos',
    antiretrovirals: 'Antirretrovirales',
    carbamazepine: 'Carbamazepina (Tegretol)',
    oxcarbazepine: 'Oxcarbazepina (Trileptal)',
    phenytoin: 'Fenitoína (Dilantin)',
    primidone: 'Primidona (Mysoline)',
    topiramate: 'Topiramato (Topamax)',
    lamotrigine: 'Lamotrigina (Lamictal)',
    rifampin: 'Rifampicina o rifabutina (Mycobutin)',
  },
};
