export const stopSmoking = {
  title: {
    averageDay: 'On average, how many cigarettes do you smoke per day?',
    howLong: 'On average, how long have you been smoking cigarettes?',
    whichOf: 'Which of the following have you tried in the past to help you quit smoking?',
    besides: 'Besides cigarettes, do you consume any of the following?',
    howMany: 'On average, how many alcoholic beverages do you drink per week?',
    anyOfThese: 'Do any of these apply to you?',
    followingMedications:
      'Some medications can interact with the medication we may prescribe you. Are you taking any of the following medications?',
  },
  options: {
    lessThanOne: 'Less than one pack per day',
    oneTwo: '1-2 packs per day',
    moreThanTwo: 'More than 2 packs a day',
    lessThanTen: '10 years or less',
    moreThanTen: '11-20 years',
    moreThanTwenty: 'More than 20 years',
    bupropion: 'Bupropion (Zyban, Wellbutrin)',
    varenicline: 'Varenicline (Chantix)',
    nicotine: 'Nicotine replacement therapy (patches, lozenges, or gum)',
    counseling: 'Counseling',
    alcohol: 'Alcohol',
    vaping: 'Nicotine (vaping, chewing tobacco, snuff, snus, pipe or cigars)',
    cannabis: 'Cannabis (inhaled or edibles)',
    drugs: 'Non-prescription or recreational drugs',
    lessThanSeven: '7 or less drinks per week',
    moreThanSeven: 'More than 7 drinks per week',
    emotionalStress: 'Significant emotional stress or major illness',
    eatingdisorder: 'Eating disorder (such as anorexia or bulimia',
    mentalHealth: 'Mental health condition (such as depression, anxiety or bipolar)',
    seizure: 'Seizure or epilepsy disorder',
    history: 'History of alcohol or substance abuse',
    heart: 'Heart, kidney or liver disease',
    glaucoma: 'Glaucoma',
    hypertension: 'Hypertension',
    currentlyPregnant: 'Currently pregnant or nursing',
    over65: 'Over 65 years old',
    barbiturates: 'Barbiturates (examples: phenobarbital, primidone)',
    benzodiazepines: 'Benzodiazepines (examples: Xanax, Ativan, Klonopin, or Valium)',
    antiepileptics: 'Antiepileptics',
  },
};
