export const stopSmoking = {
  title: {
    averageDay: 'En promedio, ¿cuántos cigarrillos fumas al día?',
    howLong: 'En promedio, ¿cuánto tiempo has estado fumando cigarrillos?',
    whichOf: '¿Cuál de las siguientes opciones has probado en el pasado para dejar de fumar?',
    besides: 'Además de cigarrillos, ¿consumes alguno de los siguientes?',
    howMany: 'En promedio, ¿cuántas bebidas alcohólicas consumes por semana?',
    anyOfThese: '¿Alguna de estas opciones se aplica a ti?',
    followingMedications:
      'Algunos medicamentos pueden interactuar con el medicamento que te podríamos recetar. ¿Estás tomando alguno de los siguientes medicamentos?',
  },
  options: {
    lessThanOne: 'Menos de un paquete por día',
    oneTwo: '1-2 paquetes por día',
    moreThanTwo: 'Más de 2 paquetes al día',
    lessThanTen: '10 años o menos',
    moreThanTen: '11-20 años',
    moreThanTwenty: 'Más de 20 años',
    bupropion: 'Bupropion (Zyban, Wellbutrin)',
    varenicline: 'Varenicline (Chantix)',
    nicotine: 'Terapia de reemplazo de nicotina (parches, pastillas o goma de mascar)',
    counseling: 'Asesoramiento',
    alcohol: 'Alcohol',
    vaping: 'Nicotina (vapeo, tabaco de mascar, rapé, snus, pipa o puros)',
    cannabis: 'Cannabis (inhalado o comestibles)',
    drugs: 'Drogas no recetadas o recreativas',
    lessThanSeven: '7 o menos bebidas por semana',
    moreThanSeven: 'Más de 7 bebidas por semana',
    emotionalStress: 'Estrés emocional significativo o enfermedad grave',
    eatingdisorder: 'Trastorno alimentario (como anorexia o bulimia)',
    mentalHealth: 'Condición de salud mental (como depresión, ansiedad o bipolaridad)',
    seizure: 'Trastorno de convulsiones o epilepsia',
    history: 'Historial de abuso de alcohol o sustancias',
    heart: 'Enfermedad cardíaca, renal o hepática',
    glaucoma: 'Glaucoma',
    hypertension: 'Hipertensión',
    currentlyPregnant: 'Actualmente embarazada o en periodo de lactancia',
    over65: 'Mayor de 65 años',
    barbiturates: 'Barbitúricos (ejemplos: fenobarbital, primidona)',
    benzodiazepines: 'Benzodiacepinas (ejemplos: Xanax, Ativan, Klonopin, o Valium)',
    antiepileptics: 'Antiepilépticos',
  },
};
