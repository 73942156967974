export const seasonalAllergy = {
  title: {
    anyOfTheseSymptoms: '¿Tienes alguno de estos síntomas?',
    anyOfTheseApply: '¿Aplica alguno de estos a ti?',
    whichSymptoms: '¿Cuáles síntomas tienes?',
    howLong: '¿Cuánto tiempo has tenido estos síntomas?',
    pregnantChance: '¿Hay alguna posibilidad de que estés embarazada?',
    wereExposed: '¿Estuviste expuesto a la gripe?',
    covidTest: '¿Tuviste un resultado positivo en una prueba de COVID en casa?',
  },
  subtitle: {
    wantMakeSure: 'Queremos asegurarnos de que no tienes algo grave.',
  },
  options: {
    swelling: 'Hinchazón de labios o lengua',
    difficultyBreathing: 'Dificultad para respirar, hablar o tragar',
    spreadingRash: 'Erupción que se propaga',
    sneezing: 'Estornudos',
    itchy: 'Ojos llorosos y con picazón',
    scratchyThroat: 'Garganta irritada',
    chanceToBePregnant: 'Hay una posibilidad de que pueda estar embarazada',
    historyOf: 'Antecedentes de alergias tratadas sin éxito',
    medicalCondition: 'Condición médica que me pone en mayor riesgo de infección',
    fever: 'Fiebre o escalofríos',
    muscleAches: 'Dolores musculares',
    diarrhea: 'Diarrea',
    soreThroat: 'Dolor de garganta',
    headache: 'Dolor de cabeza',
    cough: 'Tos',
    runnyNose: 'Secreción nasal',
    congestion: 'Congestión',
    diabetes: 'Diabetes',
    lungDisease: 'Enfermedad pulmonar',
    tobacco: 'Uso de tabaco',
    hospitalization: 'Hospitalización por infección pulmonar',
    over65: 'Mayor de 65 años',
    yes: 'Sí',
    no: 'No',
  },
};
