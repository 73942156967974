import { inject } from '@angular/core';
import { CanActivateFn } from '@angular/router';
import { AuthService } from '../services/auth.service';
import { NavController } from '@ionic/angular/standalone';

// This guard is preventing user from entering other pages without previously setting up profile
// Token is containing ID that is telling us if user is onboarded or not
// Guard should be used only on routes to which logged-in user has access to
export const registrationGuard: CanActivateFn = async () => {
  const authService = inject(AuthService);
  const navController = inject(NavController);
  const token = await authService.getTokenPayload();

  // if there is no user we go straight to splash screen without fetching additional data
  if (!token.patientId) {
    await navController.navigateForward(['registration/registration-splash']);
    return false;
  }
  // if user exists
  // we redirect him also to splash screen, but first we need to fetch his data
  const patient = await authService.getPatientData();

  // if user has all the necessary data we let him access the app
  if (isUserVerified(patient, token)) return true;

  await navController.navigateForward(['registration/registration-splash']);
  return false;
};

function isUserVerified(patient: any, token: any): boolean {
  return patient?.isEmailVerified && patient?.isPhoneVerified && patient?.isAddressVerified && token?.trusted?.v;
}
