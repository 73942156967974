import { Routes } from '@angular/router';
import { primeGuard } from '../../guards/prime.guard';
import { skipVisitRoutes } from './skip-visit/skip-visit.routes';
import { InviteRoutes } from '../account/invite/invite.routes';
import { insuranceRoutes } from '../account/insurance/insurance.routes';

export const getCareRoutes: Routes = [
  {
    path: '',
    loadComponent: () => import('./chose-care-option/chose-care-option.page').then((m) => m.ChoseCareOptionPage),
  },
  {
    path: 'off-hours',
    loadComponent: () => import('./in-person/off-hours/off-hours.component').then((m) => m.OffHoursComponent),
  },
  {
    path: 'skip-visit',
    children: skipVisitRoutes,
  },
  {
    path: ':type',
    children: [
      {
        path: 'account',
        children: [
          {
            path: '',
            loadComponent: () => import('./select-account/select-account.page').then((m) => m.SelectAccountPage),
          },
          {
            path: 'invite',
            loadComponent: () => import('../account/invite/invite.page').then((m) => m.InvitePage),
            children: InviteRoutes,
          },
        ],
      },
      {
        path: 'location',
        loadComponent: () =>
          import('./in-person/location-picker/location-picker.page').then((m) => m.LocationPickerPage),
      },
      {
        path: 'schedule',
        canActivate: [primeGuard],
        loadComponent: () => import('./virtual/schedule/schedule.page').then((m) => m.SchedulePage),
      },
      {
        path: 'time-picker',
        loadComponent: () => import('./in-person/time-picker/time-picker.page').then((m) => m.TimePickerPage),
      },
      {
        path: 'review',
        loadComponent: () => import('./schedule-review/schedule-review.page').then((m) => m.ScheduleReviewPage),
      },
      {
        path: 'insurance',
        loadComponent: () => import('../account/insurance/insurance.page').then((m) => m.InsurancePage),
        children: insuranceRoutes,
      },
      {
        path: 'pick-symptoms',
        loadComponent: () => import('./virtual/pick-symptoms/pick-symptoms.page').then((m) => m.PickSymptomsPage),
      },
      {
        path: 'visit-requested',
        loadComponent: () =>
          import('./virtual/visit-requested/visit-requested.component').then((m) => m.VisitRequestedComponent),
      },
    ],
  },
];
