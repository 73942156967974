export const ed = {
  maintain: {
    title: 'Durante el acto sexual, ¿te resulta difícil mantener la erección hasta completar el coito?',
  },
  premature: {
    title: '¿El problema parece estar relacionado generalmente con la eyaculación precoz?',
  },
  suddenGradual: {
    title: '¿Esto ocurre de repente o de manera gradual?',
    options: {
      suddenly: 'De repente',
      gradually: 'Gradualmente',
    },
  },
  spontaneous: {
    title: '¿A veces tienes erecciones espontáneas durante la noche o temprano en la mañana?',
  },
  symptoms1: {
    title: '¿Tienes alguno de los siguientes?',
    options: {
      kidneyDisease: 'Enfermedad renal',
      liverDisease: 'Enfermedad hepática',
      stroke: 'Accidente cerebrovascular o infarto de miocardio en los últimos 6 meses',
      penile: 'Deformidad peneana',
      previousPenile: 'Anterior peneano',
    },
  },
  symptoms2: {
    title: '¿Tienes alguno de los siguientes?',
    options: {
      depression: 'Depresión',
      anxiety: 'Ansiedad',
      stroke: 'Problemas de relación con tu pareja',
      diabetes: 'Diabetes',
      highBloodPressure: 'Hipertensión arterial',
      highCholesterol: 'Colesterol alto',
      tobacoUse: 'Uso de tabaco',
    },
  },
  step7: {
    title:
      'Next Level PRIME puede ayudar a manejar estos problemas. Una vez que completes el proceso para tu prescripción para la disfunción eréctil, también podrás programar una visita virtual para discutir estos problemas con más detalle.',
  },
  options: {
    yes: 'Sí',
    no: 'No',
  },
};
