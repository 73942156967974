import { NgClass } from '@angular/common';
import { Component, Input } from '@angular/core';
import { IonContent, ModalController } from '@ionic/angular/standalone';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { UpdateService } from '../../services/update.service';

@Component({
  selector: 'app-updates',
  templateUrl: './app-updates.component.html',
  styleUrls: ['./app-updates.component.scss'],
  standalone: true,
  imports: [IonContent, TranslateModule, NgClass],
})
export class AppUpdatesComponent {
  @Input() title?: any;
  @Input() body?: any;
  @Input() updates?: any[];
  @Input() footer?: any;
  @Input() actions?: any[];
  @Input() canDismiss: boolean = false;
  lang = this.translateService.currentLang;

  constructor(
    private readonly modalCtrl: ModalController,
    private readonly translateService: TranslateService,
    private readonly updateService: UpdateService,
  ) {}

  async onUpdate(): Promise<void> {
    await this.updateService.openAppStore();
  }

  onClose(): Promise<boolean> {
    return this.modalCtrl.dismiss({ source: 'modal' });
  }

  async handleAction(action: string): Promise<void> {
    switch (action) {
      case 'openAppStore': {
        await this.updateService.openAppStore();
        break;
      }
      case 'dismiss':
      default: {
        await this.modalCtrl.dismiss({ source: 'action' });
      }
    }
  }
}
