export const prime = {
  greeting: 'Good news! You’re a Prime member.',
  scheduleWithPrimeTeam: 'Schedule with your Prime Team today.',
  kickOffPrimeBenefits: 'Let’s kick off your Prime journey with the following benefits.',
  getMostOutPrime: 'Next, what are you hoping to get the most out of your Prime membership?',
  iWantNextLevelToHelpMe: 'I want Next Level Prime to help me:',
  introductoryVisit:
    'Your introductory visit with the Prime Team is about 15 minutes and virtual so you join from home or work. ',
  whenWouldYouLikeToSchedule: 'When would you like to schedule?',
  moreTimes: 'More times',
  continue: 'Continue',
  interested: 'I’m interested',
  doYouHaveAnyOtherCoverage: 'Do you have any other insurance coverage aside from Next Level Prime at the moment?',
  careOutsideNextLevelInfo:
    'We’ll never bill your insurance, but our care navigators are here to help you find the best in-network care when needed.',
  selectTimeThatworks: 'Please select a date that works best for you.',
  to: 'to',
  seeMoreDates: 'See more dates',
  noSlots: 'No available slots',
};
