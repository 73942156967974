export const hairLoss = {
  title: {
    howLong: '¿Cuánto tiempo has estado experimentando pérdida de cabello?',
    hairLossSpot: '¿Dónde está tu pérdida de cabello? Elige todas las que correspondan.',
    followingSymptoms: '¿Cuáles de los siguientes síntomas estás experimentando en las áreas de pérdida de cabello?',
    followingTherapies: '¿Cuáles de las siguientes terapias has intentado para la pérdida de cabello?',
    haveYouHad: '¿Has tenido alguno de los siguientes en los últimos 6 meses?',
  },
  options: {
    lessThanMonth: 'Menos de un mes',
    oneToTwenty: '1-12 meses',
    moreThanYear: 'Más de 1 año',
    topHead: 'Parte superior de la cabeza',
    hairline: 'Línea del cabello',
    onBody: 'En otras partes del cuerpo',
    pain: 'Dolor, sangrado, protuberancias o heridas',
    itching: 'Picazón o ardor',
    scarring: 'Cicatrización',
    minoxidil: 'Minoxidil (Rogaine)',
    finasteride: 'Finasteride (Propecia/Proscar)',
    other: 'Otros tratamientos con receta',
    surgery: 'Cirugía o procedimientos (terapia con láser, trasplante de cabello o inyecciones)',
    overCounterTreatments: 'Tratamientos de venta libre',
    hairPicking: 'Comportamiento de arrancarse el cabello',
    pressureOnHair: 'Presión frecuente sobre el cabello (como ropa ajustada, gorro o peinado)',
    historyOfCancer: 'Historia de radiación por cáncer',
    thyroid: 'Enfermedad de la tiroides, próstata o autoinmune (como lupus, psoriasis, sarcoidosis)',
    bloodDisorder: 'Trastorno sanguíneo (como anemia o deficiencia de hierro)',
    mentalHealth: 'Condición de salud mental (como depresión, ansiedad o bipolaridad)',
    sexualDysfunction: 'Disfunción sexual (como disfunción eréctil o disminución del deseo sexual)',
    frequentInfections: 'Infecciones frecuentes o condición médica que me pone en mayor riesgo de infección',
    familyHistory: 'Antecedentes familiares de pérdida de cabello',
    majorIllness: 'Enfermedad grave o cirugía',
    pregnancy: 'Embarazo',
    traumatic: 'Evento traumático o emocionalmente estresante',
    prescriptionMedications: 'Cambios en tus medicamentos recetados',
    weightLoss: 'Pérdida de peso significativa',
  },
};
