import { Routes } from '@angular/router';
import { insuranceRoutes } from '../pages/account/insurance/insurance.routes';

export const primeRoutes: Routes = [
  {
    path: '',
    pathMatch: 'full',
    redirectTo: 'schedule',
  },
  {
    path: 'schedule',
    loadComponent: () => import('./schedule/schedule.page').then((m) => m.SchedulePage),
  },
  {
    path: 'questionnaire',
    loadComponent: () => import('./questionnaire/questionnaire.page').then((m) => m.QuestionnairePage),
  },
  {
    path: 'recommendations',
    loadComponent: () => import('./recommendations/recommendations.page').then((m) => m.RecommendationsPage),
  },
  {
    path: 'other-coverage',
    loadComponent: () => import('./other-coverage/other-coverage.page').then((m) => m.OtherCoveragePage),
  },
  {
    path: 'insurance',
    loadComponent: () => import('../pages/account/insurance/insurance.page').then((m) => m.InsurancePage),
    children: insuranceRoutes,
  },
  {
    path: 'review',
    loadComponent: () => import('./schedule-review/schedule-review.page').then((m) => m.ScheduleReviewPage),
  },
];
