export const coldFluCovid = {
  title: {
    anyOfTheseSymptoms: '¿Tienes alguno de estos síntomas?',
    whichSymptoms: '¿Qué síntomas tienes?',
    anyOfTheseApply: '¿Alguno de estos te aplica?',
    wereExposed: '¿Estuviste expuesto a la gripe?',
    covidTest: '¿Tuviste una prueba casera de COVID positiva?',
  },
  subtitle: {
    wantMakeSure: 'Queremos asegurarnos de que no tengas algo grave.',
  },
  options: {
    difficultyBreathing: 'Dificultad para respirar, hablar o tragar',
    chestPain: 'Dolor en el pecho',
    coughingBlood: 'Toser sangre',
    wheezing: 'Sibilancias',
    fever: 'Fiebre o escalofríos',
    muscleAches: 'Dolores musculares',
    diarrhea: 'Diarrea',
    soreThroat: 'Dolor de garganta',
    headache: 'Dolor de cabeza',
    cough: 'Tos',
    runnyNose: 'Nariz mocosa',
    congestion: 'Congestión',
    diabetes: 'Diabetes',
    lungDisease: 'Enfermedad pulmonar',
    tobacco: 'Uso de tabaco',
    hospitalization: 'Hospitalización por infección pulmonar',
    over65: 'Mayor de 65 años',
    medicalCondition: 'Condición médica que me pone en mayor riesgo de infección',
    yes: 'Sí',
    no: 'No',
  },
};
