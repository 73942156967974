export const uti = {
  title: {
    anyOfTheseSymptoms: '¿Tienes alguno de estos síntomas?',
    whichSymptoms: '¿Cuáles síntomas tienes?',
    anyOfTheseApply: '¿Alguno de estos aplica a ti?',
    howLong: '¿Cuánto tiempo has tenido estos síntomas?',
    pregnantChance: '¿Existe la posibilidad de que estés embarazada?',
  },
  subtitle: {
    wantMakeSure: 'Queremos asegurarnos de que no tengas algo grave.',
  },
  options: {
    fever: 'Fiebre',
    nausea: 'Náuseas/vómitos',
    painInAbdomen: 'Dolor en el abdomen/lado/espalda',
    vaginalDischarge: 'Secreción vaginal',
    burning: 'Ardor',
    frequency: 'Frecuencia',
    urgency: 'Urgencia',
    lessThan: 'Menos de 5 días',
    moreThan: '5 días o más',
    yes: 'Sí',
    no: 'No',
    diabetes: 'Diabetes',
    kidneyDisease: 'Enfermedad renal',
    utiHistory: 'Historia frecuente de infecciones urinarias',
    hospitalization: 'Hospitalización por infección urinaria',
    over65: 'Más de 65 años',
    medicalCondition: 'Condición médica que me pone en mayor riesgo de infección',
    maleReproductive: 'Sistema reproductivo masculino',
  },
};
