export const hsv = {
  oral: {
    title: '¿Tienes un diagnóstico conocido de HSV oral (herpes labial)?',
    options: {
      yes: 'Sí',
      no: 'No',
    },
  },
  genital: {
    title: '¿Tienes un diagnóstico conocido de HSV genital (virus del herpes)?',
    options: {
      yes: 'Sí',
      no: 'No',
    },
  },
  anyApply: {
    title: '¿Alguno de estos se aplica a ti?',
    options: {
      diabetes: 'Diabetes',
      kidneyDisease: 'Enfermedad renal',
      over65: 'Mayor de 65 años',
      infectionRisk: 'Condición médica que me pone en mayor riesgo de infección',
    },
  },
  treatmentType: {
    title: '¿Qué tipo de tratamiento estás buscando?',
    options: {
      dailyMedication: 'Medicación diaria para suprimir el virus y limitar los brotes',
      episodicTreatment: 'Tratamiento episódico para ayudar cuando tengo un brote',
    },
  },
};
