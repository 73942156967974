import * as i0 from '@angular/core';
import { InjectionToken, inject, runInInjectionContext, INJECTOR, Injectable, Inject, Optional, ENVIRONMENT_INITIALIZER, forwardRef, NgModule, SkipSelf } from '@angular/core';
import * as i2 from 'ngx-matomo-client/core';
import { ɵINTERNAL_MATOMO_CONFIGURATION as _INTERNAL_MATOMO_CONFIGURATION, MatomoTracker, ɵrunOnce as _runOnce, ɵMATOMO_ROUTER_ENABLED as _MATOMO_ROUTER_ENABLED, ɵcreateMatomoFeature as _createMatomoFeature } from 'ngx-matomo-client/core';
import * as i1 from '@angular/router';
import { Router, PRIMARY_OUTLET, NavigationEnd } from '@angular/router';
import { of, identity, forkJoin, from, combineLatest } from 'rxjs';
import { delay, filter, distinctUntilChanged, switchMap, map, concatMap, tap, take, defaultIfEmpty, mapTo } from 'rxjs/operators';
import { Title } from '@angular/platform-browser';
import { APP_BASE_HREF, LocationStrategy } from '@angular/common';
const MATOMO_ROUTER_CONFIGURATION = new InjectionToken('MATOMO_ROUTER_CONFIGURATION');
const DEFAULT_ROUTER_CONFIGURATION = {
  prependBaseHref: true,
  trackPageTitle: true,
  delay: 0,
  exclude: [],
  navigationEndComparator: 'fullUrl'
};
const INTERNAL_ROUTER_CONFIGURATION = new InjectionToken('INTERNAL_ROUTER_CONFIGURATION', {
  factory: () => {
    const {
      disabled,
      enableLinkTracking
    } = inject(_INTERNAL_MATOMO_CONFIGURATION);
    const routerConfig = inject(MATOMO_ROUTER_CONFIGURATION, {
      optional: true
    }) || {};
    return {
      ...DEFAULT_ROUTER_CONFIGURATION,
      ...routerConfig,
      enableLinkTracking,
      disabled
    };
  }
});
const MATOMO_ROUTER_INTERCEPTORS = new InjectionToken('MATOMO_ROUTER_INTERCEPTORS');
/**
 * This is not an ideal implementation, because there exist no easy way to differentiate between a class constructor and a function.
 */
function isInterceptorFn(interceptor) {
  return typeof interceptor.prototype?.beforePageTrack !== 'function';
}
class InterceptorFnAdapter {
  constructor(fn, injector) {
    this.fn = fn;
    this.injector = injector;
  }
  beforePageTrack(event) {
    return runInInjectionContext(this.injector, () => this.fn(event));
  }
}
function provideInterceptor(typeOrFn) {
  if (isInterceptorFn(typeOrFn)) {
    return {
      provide: MATOMO_ROUTER_INTERCEPTORS,
      multi: true,
      useFactory: () => new InterceptorFnAdapter(typeOrFn, inject(INJECTOR))
    };
  } else {
    return {
      provide: MATOMO_ROUTER_INTERCEPTORS,
      multi: true,
      useClass: typeOrFn
    };
  }
}
function provideInterceptors(types) {
  if (!types) {
    return [];
  }
  return types.map(provideInterceptor);
}
function findChildRoute(route, outlet) {
  return route.children.find(child => child.outlet === outlet);
}
function getLeafRoute(route, outlet) {
  const child = findChildRoute(route, outlet);
  return child ? getLeafRoute(child, outlet) : route;
}

/**
 * Simple interceptor base looking into route's data for tracking
 *
 * @see MatomoRouteDataInterceptor
 */
class MatomoRouteInterceptorBase {
  constructor() {
    this.router = inject(Router);
  }
  beforePageTrack(event) {
    const route = this.getRoute(event);
    const data = this.extractRouteData(route);
    return this.processRouteData(data);
  }
  getRoute(_) {
    return getLeafRoute(this.router.routerState.snapshot.root, PRIMARY_OUTLET);
  }
}
const DEFAULT_DATA_KEY = 'matomo';
/** Token to define the route's data key to be looked-up by `MatomoRouteDataInterceptor`  */
const MATOMO_ROUTE_DATA_KEY = new InjectionToken('MATOMO_ROUTE_DATA_KEY', {
  providedIn: 'root',
  factory: () => DEFAULT_DATA_KEY
});
/**
 * Simple interceptor looking at 'matomo' key of route's data for tracking.
 *
 * It is possible to extend this class or {@link MatomoRouteInterceptorBase}
 * for custom behavior (to use another data key, etc.)
 *
 * @example
 * // Using provided MatomoRouteDataInterceptor (looks into 'matomo' data key)
 * const routes: Routes = [
 *   {
 *     path: '/hello',
 *     component: HelloComponent,
 *     data: {
 *       matomo: {
 *         title: 'Page title',
 *       } as MatomoRouteData
 *     }
 *   },
 * ];
 *
 * NgxMatomoRouterModule.forRoot({
 *   interceptors: [MatomoRouteDataInterceptor],
 * }),
 *
 * @example
 * // Using custom 'myCustomAnalyticsKey' data key
 * const routes: Routes = [
 *   {
 *     path: '/hello',
 *     component: HelloComponent,
 *     data: {
 *       myCustomAnalyticsKey: {
 *         title: 'Page title',
 *       } as MatomoRouteData
 *     }
 *   },
 * ];
 *
 * @Injectable()
 * export class MyCustomInterceptor extends MatomoRouteDataInterceptor {
 *   readonly dataKey = 'myCustomAnalyticsKey';
 * }
 *
 * NgxMatomoRouterModule.forRoot({
 *   interceptors: [MyCustomInterceptor],
 * }),
 *
 * @see MatomoRouteInterceptorBase
 * @see MatomoRouteData
 */
class MatomoRouteDataInterceptor extends MatomoRouteInterceptorBase {
  constructor() {
    super(...arguments);
    this.tracker = inject(MatomoTracker);
    this.dataKey = inject(MATOMO_ROUTE_DATA_KEY);
  }
  extractRouteData(route) {
    return route.data[this.dataKey];
  }
  processRouteData(data) {
    if (!data) {
      return;
    }
    if (data.title) {
      this.tracker.setDocumentTitle(data.title);
    }
    if (data.ecommerce) {
      this.tracker.setEcommerceView(data.ecommerce);
    }
  }
  static {
    this.ɵfac = /* @__PURE__ */(() => {
      let ɵMatomoRouteDataInterceptor_BaseFactory;
      return function MatomoRouteDataInterceptor_Factory(t) {
        return (ɵMatomoRouteDataInterceptor_BaseFactory || (ɵMatomoRouteDataInterceptor_BaseFactory = i0.ɵɵgetInheritedFactory(MatomoRouteDataInterceptor)))(t || MatomoRouteDataInterceptor);
      };
    })();
  }
  static {
    this.ɵprov = /* @__PURE__ */i0.ɵɵdefineInjectable({
      token: MatomoRouteDataInterceptor,
      factory: MatomoRouteDataInterceptor.ɵfac
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(MatomoRouteDataInterceptor, [{
    type: Injectable
  }], null, null);
})();
const ROUTER_ALREADY_INITIALIZED_ERROR = 'MatomoRouter has already been initialized';
function invalidInterceptorsProviderError() {
  return new Error('An invalid MATOMO_ROUTER_INTERCEPTORS provider was configured. Did you forget to set "multi: true" ?');
}

/**
 * @deprecated Use an interceptor calling `setDocumentTitle()` instead
 * @see MatomoRouterInterceptor
 * @see MATOMO_ROUTER_INTERCEPTORS
 */
const MATOMO_PAGE_TITLE_PROVIDER = new InjectionToken('MATOMO_PAGE_TITLE_PROVIDER', {
  factory: () => new DefaultPageTitleProvider(inject(Title))
});
class DefaultPageTitleProvider {
  constructor(title) {
    this.title = title;
  }
  getCurrentPageTitle(_) {
    return of(this.title.getTitle());
  }
}
const MATOMO_PAGE_URL_PROVIDER = new InjectionToken('MATOMO_PAGE_URL_PROVIDER', {
  factory: () => new DefaultPageUrlProvider(inject(INTERNAL_ROUTER_CONFIGURATION), inject(APP_BASE_HREF, {
    optional: true
  }), inject(LocationStrategy))
});
function trimTrailingSlash(str) {
  return str.endsWith('/') ? str.slice(0, -1) : str;
}
class DefaultPageUrlProvider {
  constructor(config, baseHref, locationStrategy) {
    this.config = config;
    this.baseHref = baseHref;
    this.locationStrategy = locationStrategy;
  }
  getCurrentPageUrl(event) {
    const url = this.config.prependBaseHref ? this.getBaseHrefWithoutTrailingSlash() + event.urlAfterRedirects : event.urlAfterRedirects;
    return of(url);
  }
  getBaseHrefWithoutTrailingSlash() {
    return trimTrailingSlash(this.baseHref ?? this.locationStrategy.getBaseHref() ?? '');
  }
}
function isNavigationEnd(event) {
  return event instanceof NavigationEnd;
}
function coerceRegExp(input) {
  return typeof input === 'string' ? new RegExp(input) : input;
}
function coerceRegExpArray(input) {
  if (!input) {
    return [];
  }
  return Array.isArray(input) ? input.map(coerceRegExp) : [coerceRegExp(input)];
}
function isNotExcluded(excludeConfig) {
  const exclusions = coerceRegExpArray(excludeConfig);
  return event => !exclusions.some(rx => event.urlAfterRedirects.match(rx));
}
function stripQueryParams(url) {
  return url.split('?')[0];
}
function defaultNavigationEndComparator(urlExtractor) {
  return (eventA, eventB) => urlExtractor(eventA) === urlExtractor(eventB);
}
function getNavigationEndComparator(config) {
  switch (config.navigationEndComparator) {
    case 'fullUrl':
      return defaultNavigationEndComparator(event => event.urlAfterRedirects);
    case 'ignoreQueryParams':
      return defaultNavigationEndComparator(event => stripQueryParams(event.urlAfterRedirects));
    default:
      return config.navigationEndComparator;
  }
}
class MatomoRouter {
  constructor(router, config, pageTitleProvider, pageUrlProvider, tracker, interceptors) {
    this.router = router;
    this.config = config;
    this.pageTitleProvider = pageTitleProvider;
    this.pageUrlProvider = pageUrlProvider;
    this.tracker = tracker;
    this.interceptors = interceptors;
    this.initialize = _runOnce(() => {
      if (this.config.disabled) {
        // Do not set-up router if globally disabled
        return;
      }
      const delayOp = this.config.delay === -1 ? identity : delay(this.config.delay);
      const navigationEndComparator = getNavigationEndComparator(this.config);
      this.router.events.pipe(
      // Take only NavigationEnd events
      filter(isNavigationEnd),
      // Filter out excluded urls
      filter(isNotExcluded(this.config.exclude)),
      // Filter out NavigationEnd events to ignore, e.g. when url does not actually change (component reload)
      distinctUntilChanged(navigationEndComparator),
      // Optionally add some delay
      delayOp,
      // Set default page title & url
      switchMap(event => this.presetPageTitleAndUrl(event).pipe(map(({
        pageUrl
      }) => ({
        pageUrl,
        event
      })))),
      // Run interceptors then track page view
      concatMap(({
        event,
        pageUrl
      }) => this.callInterceptors(event).pipe(tap(() => this.trackPageView(pageUrl))))).subscribe();
    }, ROUTER_ALREADY_INITIALIZED_ERROR);
    if (interceptors && !Array.isArray(interceptors)) {
      throw invalidInterceptorsProviderError();
    }
  }
  /** @deprecated use {@link initialize initialize()} instead */
  init() {
    this.initialize();
  }
  callInterceptors(event) {
    if (this.interceptors) {
      return forkJoin(this.interceptors.map(interceptor => {
        const result = interceptor.beforePageTrack(event);
        const result$ = result == null ? of(undefined) : from(result);
        // Must not be an empty observable (otherwise forkJoin would complete without waiting others)
        return result$.pipe(take(1), defaultIfEmpty(undefined));
      })).pipe(mapTo(undefined), defaultIfEmpty(undefined));
    } else {
      return of(undefined);
    }
  }
  presetPageTitleAndUrl(event) {
    const title$ = this.config.trackPageTitle ? this.pageTitleProvider.getCurrentPageTitle(event).pipe(tap(pageTitle => this.tracker.setDocumentTitle(pageTitle))) : of(undefined);
    const url$ = this.pageUrlProvider.getCurrentPageUrl(event).pipe(tap(pageUrl => this.tracker.setCustomUrl(pageUrl)));
    return combineLatest([title$, url$]).pipe(map(([_, pageUrl]) => ({
      pageUrl
    })));
  }
  trackPageView(pageUrl) {
    this.tracker.trackPageView();
    if (this.config.enableLinkTracking) {
      this.tracker.enableLinkTracking(this.config.enableLinkTracking === 'enable-pseudo');
    }
    // Set referrer for next page view
    this.tracker.setReferrerUrl(pageUrl);
  }
  static {
    this.ɵfac = function MatomoRouter_Factory(t) {
      return new (t || MatomoRouter)(i0.ɵɵinject(i1.Router), i0.ɵɵinject(INTERNAL_ROUTER_CONFIGURATION), i0.ɵɵinject(MATOMO_PAGE_TITLE_PROVIDER), i0.ɵɵinject(MATOMO_PAGE_URL_PROVIDER), i0.ɵɵinject(i2.MatomoTracker), i0.ɵɵinject(MATOMO_ROUTER_INTERCEPTORS, 8));
    };
  }
  static {
    this.ɵprov = /* @__PURE__ */i0.ɵɵdefineInjectable({
      token: MatomoRouter,
      factory: MatomoRouter.ɵfac,
      providedIn: 'root'
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(MatomoRouter, [{
    type: Injectable,
    args: [{
      providedIn: 'root'
    }]
  }], () => [{
    type: i1.Router
  }, {
    type: undefined,
    decorators: [{
      type: Inject,
      args: [INTERNAL_ROUTER_CONFIGURATION]
    }]
  }, {
    type: undefined,
    decorators: [{
      type: Inject,
      args: [MATOMO_PAGE_TITLE_PROVIDER]
    }]
  }, {
    type: undefined,
    decorators: [{
      type: Inject,
      args: [MATOMO_PAGE_URL_PROVIDER]
    }]
  }, {
    type: i2.MatomoTracker
  }, {
    type: undefined,
    decorators: [{
      type: Optional
    }, {
      type: Inject,
      args: [MATOMO_ROUTER_INTERCEPTORS]
    }]
  }], null);
})();

/** Enable automatic page views tracking */
function withRouter(config) {
  const providers = [{
    provide: _MATOMO_ROUTER_ENABLED,
    useValue: true
  }, {
    provide: MATOMO_ROUTER_CONFIGURATION,
    useValue: config
  }, {
    provide: ENVIRONMENT_INITIALIZER,
    multi: true,
    useValue() {
      inject(MatomoRouter).initialize();
    }
  }];
  return _createMatomoFeature("Router" /* RouterMatomoFeatureKind.Router */, providers);
}
/** Add some matomo router interceptors */
function withRouterInterceptors(interceptors) {
  return _createMatomoFeature("RouterInterceptors" /* RouterMatomoFeatureKind.RouterInterceptors */, provideInterceptors(interceptors), featuresKind => {
    if (!featuresKind.includes("Router" /* RouterMatomoFeatureKind.Router */)) {
      throw new Error(`Matomo feature withRouterInterceptors() cannot be used without router feature! Did you forget to call withRouter()?`);
    }
  });
}
/**
 * Enable retrieval of tracking information from route data
 *
 * @see MatomoRouteData
 * @param key A custom key to get lookup route data - default is 'matomo'
 */
function withRouteData(key) {
  const providers = [provideInterceptor(MatomoRouteDataInterceptor)];
  if (key) {
    providers.push({
      provide: MATOMO_ROUTE_DATA_KEY,
      useValue: key
    });
  }
  return _createMatomoFeature("BuiltInRouteDataInterceptor" /* RouterMatomoFeatureKind.BuiltInRouteDataInterceptor */, providers, featuresKind => {
    if (!featuresKind.includes("Router" /* RouterMatomoFeatureKind.Router */)) {
      throw new Error(`Matomo feature withRouteData() cannot be used without router feature! Did you forget to call withRouter()?`);
    }
  });
}
class MatomoRouterModule {
  constructor(router, parent, parentDeprecated) {
    this.router = router;
    if (!parent && !parentDeprecated) {
      // Do not initialize if it is already (by a parent module)
      this.router.initialize();
    }
  }
  static forRoot(configWithInterceptors = {}) {
    // Note: not using "rest" syntax here, in order to avoid any dependency on tslib (and reduce package size)
    // The only drawback of this is that MATOMO_ROUTER_CONFIGURATION will actually
    // contain a reference to provided interceptors
    return {
      ngModule: MatomoRouterModule,
      providers: [{
        provide: MATOMO_ROUTER_CONFIGURATION,
        useValue: configWithInterceptors
      }, provideInterceptors(configWithInterceptors.interceptors)]
    };
  }
  static {
    this.ɵfac = function MatomoRouterModule_Factory(t) {
      return new (t || MatomoRouterModule)(i0.ɵɵinject(MatomoRouter), i0.ɵɵinject(MatomoRouterModule, 12), i0.ɵɵinject(forwardRef(() => NgxMatomoRouterModule), 12));
    };
  }
  static {
    this.ɵmod = /* @__PURE__ */i0.ɵɵdefineNgModule({
      type: MatomoRouterModule
    });
  }
  static {
    this.ɵinj = /* @__PURE__ */i0.ɵɵdefineInjector({
      providers: [{
        provide: _MATOMO_ROUTER_ENABLED,
        useValue: true
      }]
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(MatomoRouterModule, [{
    type: NgModule,
    args: [{
      providers: [{
        provide: _MATOMO_ROUTER_ENABLED,
        useValue: true
      }]
    }]
  }], () => [{
    type: MatomoRouter
  }, {
    type: MatomoRouterModule,
    decorators: [{
      type: Optional
    }, {
      type: SkipSelf
    }]
  }, {
    type: NgxMatomoRouterModule,
    decorators: [{
      type: Inject,
      args: [forwardRef(() => NgxMatomoRouterModule)]
    }, {
      type: Optional
    }, {
      type: SkipSelf
    }]
  }], null);
})();
/**
 * @deprecated use MatomoRouterModule instead
 * @breaking-change 7.0.0
 */
class NgxMatomoRouterModule {
  constructor(router, parent, parentDeprecated) {
    this.router = router;
    if (!parent && !parentDeprecated) {
      // Do not initialize if it is already (by a parent module)
      this.router.initialize();
    }
  }
  static forRoot(configWithInterceptors = {}) {
    // Note: not using "rest" syntax here, in order to avoid any dependency on tslib (and reduce package size)
    // The only drawback of this is that MATOMO_ROUTER_CONFIGURATION will actually
    // contain a reference to provided interceptors
    return {
      ngModule: NgxMatomoRouterModule,
      providers: [{
        provide: MATOMO_ROUTER_CONFIGURATION,
        useValue: configWithInterceptors
      }, provideInterceptors(configWithInterceptors.interceptors)]
    };
  }
  static {
    this.ɵfac = function NgxMatomoRouterModule_Factory(t) {
      return new (t || NgxMatomoRouterModule)(i0.ɵɵinject(MatomoRouter), i0.ɵɵinject(MatomoRouterModule, 12), i0.ɵɵinject(forwardRef(() => NgxMatomoRouterModule), 12));
    };
  }
  static {
    this.ɵmod = /* @__PURE__ */i0.ɵɵdefineNgModule({
      type: NgxMatomoRouterModule
    });
  }
  static {
    this.ɵinj = /* @__PURE__ */i0.ɵɵdefineInjector({
      providers: [{
        provide: _MATOMO_ROUTER_ENABLED,
        useValue: true
      }]
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(NgxMatomoRouterModule, [{
    type: NgModule,
    args: [{
      providers: [{
        provide: _MATOMO_ROUTER_ENABLED,
        useValue: true
      }]
    }]
  }], () => [{
    type: MatomoRouter
  }, {
    type: MatomoRouterModule,
    decorators: [{
      type: Optional
    }, {
      type: SkipSelf
    }]
  }, {
    type: NgxMatomoRouterModule,
    decorators: [{
      type: Inject,
      args: [forwardRef(() => NgxMatomoRouterModule)]
    }, {
      type: Optional
    }, {
      type: SkipSelf
    }]
  }], null);
})();

/*
 * Public API Surface of tracker
 */

/**
 * Generated bundle index. Do not edit.
 */

export { MATOMO_PAGE_TITLE_PROVIDER, MATOMO_PAGE_URL_PROVIDER, MATOMO_ROUTER_CONFIGURATION, MATOMO_ROUTER_INTERCEPTORS, MATOMO_ROUTE_DATA_KEY, MatomoRouteDataInterceptor, MatomoRouteInterceptorBase, MatomoRouterModule, NgxMatomoRouterModule, provideInterceptor, provideInterceptors, withRouteData, withRouter, withRouterInterceptors };
