export const sinusInfection = {
  title: {
    anyOfTheseSymptoms: 'Do you have any of these symptoms?',
    anyOfTheseApply: 'Do any of these apply to you?',
    whichSymptoms: 'Which symptoms do you have?',
    howLong: 'How long have you had these symptoms?',
    wereExposed: 'Were you exposed to the flu?',
    covidTest: 'Did you have positive home COVID test',
  },
  subtitle: {
    wantMakeSure: 'We want to make sure you don’t have something serious.',
  },
  options: {
    stiffNeck: 'Stiff or painful neck',
    difficultyBreathing: 'Difficulty breathing, speaking, or swallowing',
    severeHeadache: 'Severe or worsening headache',
    nasalCongestion: 'Nasal congestion',
    facialPain: 'Facial pain or pressure',
    mucusDripping: 'Mucus dripping down throat',
    symptomsLastingMore: 'Symptoms lasting more than 10 days without improving',
    symptomsGotWorse: 'Symptoms got worse after improving',
    diabetes: 'Diabetes',
    over65: 'Over 65 years old',
    medicalCondition: 'Medical condition that puts me at higher risk of infection',
    tobaccoUser: 'Tobacco user',
    historyOfSinus: 'History of sinus, nasal, or facial surgery',
    moreThan: 'More than 3 sinus infections in last year',
    fever: 'Fever or chills',
    muscleAches: 'Muscle aches',
    diarrhea: 'Diarrhea',
    soreThroat: 'Sore throat',
    headache: 'Headache',
    cough: 'Cough',
    runnyNose: 'Runny nose',
    congestion: 'Congestion',
    lungDisease: 'Lung disease',
    tobacco: 'Tobacco use',
    hospitalization: 'Hospitalization for lung infection',
    yes: 'Yes',
    no: 'No',
  },
};
