export const vyi = {
  title: {
    whichSymptoms: 'Which symptoms do you have?',
    haveAnyOf: 'Do you have any of the following?',
    anyOfTheseApply: 'Do any of these apply to you?',
    howLong: 'How long have you had these symptoms?',
    pregnantChance: 'Is there a chance you might be pregnant?',
    haveYou:
      'Have you been prescribed an antibiotic and want a pill to prevent a yeast infection and currently have no symptoms?',
  },
  options: {
    yes: 'Yes',
    no: 'No',
    newPartner: 'New sexual partner',
    painInAbdomen: 'Pain in abdomen/side/back',
    fever: 'Fever/chills',
    vaginalBleeding: 'Vaginal bleeding',
    sexuallyTransmittedInfection: 'Concern for a sexually transmitted infection',
    newSmell: 'Thin discharge with new smell or odor',
    painfulUrination: 'Painful urination',
    pain: 'Pain with sexual intercourse',
    vaginalSores: 'Vaginal sores',
    diabetes: 'Diabetes',
    kidneyDisease: 'Kidney disease',
    liverDisease: 'Liver disease',
    over65: 'Over 65 years old',
    medicalCondition: 'Medical condition that puts me at a higher risk for infection',
    vaginalDischarge: 'Increased or change in vaginal discharge',
    vaginalItching: 'Vaginal itching',
    vaginalIrritation: 'Vaginal irritation/burning',
    lessThan: 'Less than 3 days',
    moreThan: '3 or more days',
  },
};
