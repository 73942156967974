import { inject } from '@angular/core';
import { CanActivateFn } from '@angular/router';
import { AuthService } from '../services/auth.service';
import { NavController } from '@ionic/angular/standalone';

export const primeGuard: CanActivateFn = async () => {
  const authService = inject(AuthService);
  const navController = inject(NavController);

  if (authService.isPrime()) return true;

  await navController.navigateBack(['home']);
  return false;
};
