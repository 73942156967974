export const ed = {
  maintain: {
    title:
      'During sexual intercourse, do you find it difficult to maintain your erection to completion of intercourse?',
  },
  premature: {
    title: 'Does the issue usually seem related to premature ejaculation?',
  },
  suddenGradual: {
    title: 'Does this come suddenly or gradually?',
    options: {
      suddenly: 'Suddenly',
      gradually: 'Gradually',
    },
  },
  spontaneous: {
    title: 'Do you sometimes have spontaneous nighttime or early morning erections?',
  },
  symptoms1: {
    title: 'Do you have any of the following?',
    options: {
      kidneyDisease: 'Kidney disease',
      liverDisease: 'Liver disease',
      stroke: 'Stroke or heart attack in the last 6 months',
      penile: 'Penile deformity',
      previousPenile: 'Previous penile',
    },
  },
  symptoms2: {
    title: 'Do you have any of the following?',
    options: {
      depression: 'Depression',
      anxiety: 'Anxiety',
      stroke: 'Relationship issues with your partner',
      diabetes: 'Diabetes',
      highBloodPressure: 'High Blood Pressure',
      highCholesterol: 'High Cholesterol',
      tobacoUse: 'Tobacco use',
    },
  },
  step7: {
    title:
      'Next Level PRIME can assist in managing these issues. Once you complete the process for your prescription for erectile dysfunction, you may also schedule a virtual visit to discuss these issues in more detail.',
  },
  options: {
    yes: 'Yes',
    no: 'No',
  },
};
