export const foodPoisoning = {
  title: {
    anyOfTheseSymptoms: 'Do you have any of these symptoms?',
    anyOfTheseApply: 'Do any of these apply to you?',
    whichSymptoms: 'Which symptoms do you have?',
    howLong: 'How long have you had these symptoms?',
    pregnantChance: 'Is there a chance you might be pregnant?',
  },
  subtitle: {
    wantMakeSure: 'We want to make sure you don’t have something serious.',
  },
  options: {
    yes: 'Yes',
    no: 'No',
    severePain: 'Severe abdominal pain',
    bloody: 'Bloody or black stools or vomit',
    noUrination: 'No urination for 12 hours or more',
    unableToDrink: 'Unable to drink fluids',
    lightheadedness: 'Lightheadedness',
    moreWater: '3 or more water stools per day',
    generalizedPain: 'Crampy generalized abdominal pain',
    nausea: 'Nausea or vomiting after eating',
    lessThan: 'Less than 3 days',
    moreThan: '3 or more days',
    diabetes: 'Diabetes',
    over65: 'Over 65 years old',
    fever: 'Fever',
    medicalCondition: 'Medical condition that puts me at higher risk for infection',
    hospitalization: 'Hospitalization in last 30 days',
    antibiotic: 'Antibiotic use in last 30 days',
  },
};
