export const hairLoss = {
  title: {
    howLong: 'How long have you been experiencing hair loss?',
    hairLossSpot: 'Where is your hair loss? Choose all that apply.',
    followingSymptoms: 'Which of the following symptoms are you experiencing in areas of hair loss?',
    followingTherapies: 'Which of the following therapies have you tried for hair loss?',
    haveYouHad: 'Have you had any of the following in the last 6 months?',
  },
  options: {
    lessThanMonth: 'Less than a month',
    oneToTwenty: '1-12 months',
    moreThanYear: 'More than 1 year',
    topHead: 'Top of head',
    hairline: 'Hairline',
    onBody: 'Elsewhere on body',
    pain: 'Pain, bleeding, bumps or wounds',
    itching: 'Itching or burning',
    scarring: 'Scarring',
    minoxidil: 'Minoxidil (Rogaine)',
    finasteride: 'Finasteride (Propecia/Proscar)',
    other: 'Other prescription treatments',
    surgery: 'Surgery or procedures (laster therapy, hair transplant or injections)',
    overCounterTreatments: 'Over the counter treatments',
    hairPicking: 'Hair picking or pulling behaviour',
    pressureOnHair: 'Frequent pressure on hair (such as tight clothing, hat or hairdo',
    historyOfCancer: 'History of cancer radiation',
    thyroid: 'Thyroid, prostate, or autoimmune disease (such as lupus, psoriasis, sarcoidosis)',
    bloodDisorder: 'Blood disorder (such as anemia or iron deficiency)',
    mentalHealth: 'Mental health condition (such as depression, anxiety or bipolar)',
    sexualDysfunction: 'Sexual dysfunction (such as erectile dysfunction or decreased sex drive)',
    frequentInfections: 'Frequent infections or medical condition that puts me at higher risk for infection',
    familyHistory: 'Family history of hair loss',
    majorIllness: 'Major illness or surgery',
    pregnancy: 'Pregnancy',
    traumatic: 'Traumatic or emotionally stressful event',
    prescriptionMedications: 'Changes to your prescription medications',
    weightLoss: 'Significant weight loss',
  },
};
