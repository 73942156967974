export const stomachBug = {
  title: {
    anyOfTheseSymptoms: '¿Tienes alguno de estos síntomas?',
    whichSymptoms: '¿Qué síntomas tienes?',
    anyOfTheseApply: '¿Te aplica alguno de estos?',
    howLong: '¿Cuánto tiempo has tenido estos síntomas?',
    pregnantChance: '¿Hay alguna posibilidad de que estés embarazada?',
  },
  subtitle: {
    wantMakeSure: 'Queremos asegurarnos de que no tienes algo grave.',
  },
  options: {
    severeAbdominalPain: 'Dolor abdominal severo',
    bloodyStools: 'Heces o vómitos con sangre o negros',
    noUrination: 'No ha orinado durante 12 horas o más',
    unableToDrinkFluids: 'Incapaz de beber líquidos',
    lightheadedness: 'Mareos',

    wateryStools: '3 o más deposiciones acuosas por día',
    crampyGeneralized: 'Dolor abdominal generalizado con cólicos',
    nausea: 'Náuseas o vómitos después de comer',
    lessThan: 'Menos de 3 días',
    moreThan: '3 días o más',
    yes: 'Sí',
    no: 'No',
    diabetes: 'Diabetes',
    over65: 'Mayor de 65 años',
    fever: 'Fiebre',
    antibioticUse: 'Uso de antibióticos en los últimos 30 días',
    hospitalization: 'Hospitalización en los últimos 30 días',
    medicalCondition: 'Condición médica que me pone en mayor riesgo de infección',
  },
};
