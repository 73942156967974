export const hsv = {
  oral: {
    title: 'Do you have a known diagnosis of oral HSV (cold sores)?',
    options: {
      yes: 'Yes',
      no: 'No',
    },
  },
  genital: {
    title: 'Do you have a known diagnosis of genital HSV (herpes virus)?',
    options: {
      yes: 'Yes',
      no: 'No',
    },
  },
  anyApply: {
    title: 'Do any of these apply to you?',
    options: {
      diabetes: 'Diabetes',
      kidneyDisease: 'Kidney disease',
      over65: 'Over 65 years old',
      infectionRisk: 'Medical condition that puts me at higher risk for infection',
    },
  },
  treatmentType: {
    title: 'What type of treatment are you looking for?',
    options: {
      dailyMedication: 'Daily medication to suppress the virus and limit outbreaks',
      episodicTreatment: 'Episodic treatment to help when I have an outbreak',
    },
  },
};
