export const coldFluCovid = {
  title: {
    anyOfTheseSymptoms: 'Do you have any of these symptoms?',
    whichSymptoms: 'Which symptoms do you have?',
    anyOfTheseApply: 'Do any of these apply to you?',
    wereExposed: 'Were you exposed to the flu?',
    covidTest: 'Did you have positive home COVID test',
  },
  subtitle: {
    wantMakeSure: 'We want to make sure you don’t have something serious.',
  },
  options: {
    difficultyBreathing: 'Difficulty breathing, speaking, or swallowing',
    chestPain: 'Chest pain',
    coughingBlood: 'Coughing up blood',
    wheezing: 'Wheezing',
    fever: 'Fever or chills',
    muscleAches: 'Muscle aches',
    diarrhea: 'Diarrhea',
    soreThroat: 'Sore throat',
    headache: 'Headache',
    cough: 'Cough',
    runnyNose: 'Runny nose',
    congestion: 'Congestion',
    diabetes: 'Diabetes',
    lungDisease: 'Lung disease',
    tobacco: 'Tobacco use',
    hospitalization: 'Hospitalization for lung infection',
    over65: 'Over 65 years old',
    medicalCondition: 'Medical condition that puts me at higher risk for infection',
    yes: 'Yes',
    no: 'No',
  },
};
